.investor-profile {
    background-color: var(--color-foreground);
    padding: 20px;
    border-radius: 10px;
    color: var(--color-text);
    height: 100%;
    overflow-x: auto;
}

.investor-profile::-webkit-scrollbar {
    height: 5px
}

.investor-profile::-webkit-scrollbar-thumb {
    background-color: var(--color-scroll);
}

.investor-profile::-webkit-scrollbar-track {
    background-color: var(--color-foreground)
}

.investor-profile-inner-container {
    display: flex;
    min-width: 550px;
}

.investor-profile-company-name {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 10px;
}

.investor-profile-investor-name {
    font-size: 16px;
    font-weight: 600;
}

.investor-profile-cik {
    font-size: 12px;
    font-weight: 400;
}

.holding-table > thead > tr > * {
    min-width: 100px;
}

.holding-table > thead > tr > .watchlist {
    min-width: 40px;
}

.holding-table > thead > tr > .name {
    min-width: 150px;
}

.holding-table > thead > tr > .activity {
    min-width: 120px;
}

.holding-table > thead > tr > .quarterEndPrice {
    min-width: 120px;
}

.holding-table > thead > tr > .marketValue {
    min-width: 120px;
}

.holdings-filter-div {
    display: flex;
    margin: 10px 0;
    overflow-x: auto;
}

.holding-filter-btn {
    font-size: 12px;
    font-weight: 600;
    margin-right: 10px;
    /*width: 97%;*/
    /*margin-right: 10px;*/
    /*min-width: 90px;*/
}

.holding-filter-no-changes-btn {
    /*min-width: 121px;*/
    /*margin-right: 10px;*/
    /*min-width: 90px;*/
}

.holdings-btn-new-buy {
    color: white;
    background-color: #54cb97;
}

.holdings-btn-sold-out {
    color: white;
    background-color: #ff646e;
}

/*@media screen and (max-width: 768px) {*/
/*    .holding-filter-btn {*/
/*        font-size: 12px;*/
/*        margin: 0 0 5px 0;*/
/*        !*font-weight: 400;*!*/
/*    }*/
/*}*/

@media screen and (max-width: 576px) {
    .holding-filter-btn {
        width: 95%;
        margin-bottom: 5px;
    }
}

.holding-guru-container {
    background-color: var(--color-foreground);
    padding: 20px;
    border-radius: 10px;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.investor-holding {
    background-color: var(--color-foreground);
    padding: 20px;
    border-radius: 10px;
    height: 100%;
    display: flex;
    flex-direction: column;
    cursor: pointer;

    .investor-profile-company-name {
        font-size: 14px;
    }

    .investor-profile-investor-name {
        font-size: 14px;
    }

    .investor-profile-cik {
        font-size: 12px;
    }

    .holding-profile-image-container {
        min-width: 60px;
        min-height: 60px;
    }

    .holding-profile-highlights-title {
        font-size: 12px;
        font-weight: 600;
    }

    .holding-profile-highlights-content {
        font-size: 12px;
        font-weight: 600;
    }
}

.guru-search-sort-bar {
    display: flex;
    width: 100%;

    & select {
        margin: auto 0 auto auto;
    }
}
