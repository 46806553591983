.navbar-container {
    width: 100%;
    padding: 0 27px;
    height: 65px;
    display: flex;
    background-color: var(--color-foreground);
}

.hamburger {
    font-size: 20px;
    margin: auto 0;
    cursor: pointer;
    color: var(--color-text);
}

.logo-lg {
    width: 120px;
}

.logo-lg-a {
    margin: auto 30px;
}

.logo-sm-a {
    margin: auto 30px;
    display: none;
}

.logo-sm {
    margin: auto 0;
    width: 30px;
}

@media screen and (max-width: 768px) {
    .logo-lg-a {
        display: none;
    }

    .logo-sm-a {
        display: block;
    }
}


.search-bar-container-lg {
    width: 360px;
    display: flex;
    border-radius: 15px;
    padding: 7px 20px;
    border: 1px solid #265862;
    cursor: pointer;
    background-color: var(--color-background);
}

.navbar-theme-switch {
    display: block;
    margin: auto 0;
}

.search-bar-container-sm {
    display: none;
}

.search-bar-container-text {
    color: #969696;
    font-size: 14px;
    font-weight: 400;
    margin: auto auto auto 0;
}

@media screen and (max-width: 950px) {
    .search-bar-container-lg {
        width: 270px;
    }
}

@media screen and (max-width: 880px) {
    .search-bar-container-lg {
        width: 200px;
    }
}

@media screen and (max-width: 768px) {
    .search-bar-container-lg {
        display: none;
    }

    .search-bar-container-sm {
        display: block;
    }

    .navbar-theme-switch {
        display: none;
    }
}

.search-bar-icon {
    margin: auto 10px auto 0;
    color: var(--color-text);
    font-size: 14px;
}

.home-page-company-container {
    border: 1px solid #265862;
    display: flex;
    padding: 10px;
    border-radius: 10px;
    margin: auto 10px auto 0;
    cursor: pointer;
}

.home-page-company-container:hover {
    background-color: #265862;
}

.home-page-company-logo {
    width: 25px;
    height: 25px;
    border-radius: 5px;
    margin: auto 5px;
}

.home-page-company-label {
    font-size: 16px;
    font-weight: 600;
    margin: auto 10px;
    color: white;
}

@media screen and (max-width: 768px) {
    .home-page-company-container {
        padding: 5px 6px;
        margin: auto 5px auto 0;
    }

    .home-page-company-logo {
        width: 20px;
        height: 20px;
    }

    .home-page-company-label {
        font-size: 12px;
        font-weight: 500;
        margin: auto 5px;
    }
}

.bell-container {
    margin: auto 0 auto auto;
    padding: 10px;
    width: 45px;
    height: 45px;
    display: flex;
    border-radius: 50%;
    background-color: var(--color-background);
}

.bell-icon {
    font-size: 20px;
    margin: auto;
    color: var(--color-text);
}

.profile-image-container {
    margin: auto 0 auto 10px;
    border-radius: 50px;
    width: 40px;
    height: 40px;
    overflow: hidden;
    border: 1px solid #1BA1DA;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.holding-profile-image-container {
    min-width: 80px;
    min-height: 80px;
    margin: auto 10px auto 0;
    border-radius: 10px;
}

.holding-profile-text-container {
    color: var(--color-text);
}

.holding-profile-highlights-container {
    /*width: 230px;*/
    margin: auto 0 0 0;
}

.holding-profile-highlights-div {
    display: flex;
    width: 100%;
    margin-bottom: 10px;
}

.holding-profile-highlights-title {
    font-size: 13px;
    font-weight: 700;
    margin: 0 10px 0 0;
    color: var(--color-text);
}

.holding-profile-highlights-content {
    font-size: 13px;
    font-weight: 700;
    margin-left: auto;
    color: var(--color-text);
}

.profile-image {
    width: 40px;
}

.search-modal-body {
    background-color: var(--color-foreground);
    padding: 20px;
    width: 600px;
}

@media screen and (max-width: 567px) {
    .search-modal-body {
        padding: 10px;
        width: 370px;
    }
}

.search-modal-text-heading {
    color: var(--color-text);
    font-size: 14px;
    font-weight: 600;
}

.search-close-modal-button {
    margin: auto 0 auto auto;
    font-size: 14px;
    color: var(--color-text);
    cursor: pointer;
}

.navbar-tab-container {
    margin: 10px 0;
}

@media screen and (max-width: 768px) {
    .navbar-tab-container {
        margin: 10px 0;
    }
}

.navbar-tab-panel {
    background-color: var(--color-foreground) !important;
}

.navbar-modal-container {
    width: 400px;
    display: flex;
    border-radius: 20px;
    padding: 5px 20px 5px 5px;
    border: 1px solid #265862;
    cursor: pointer;
}

@media screen and (max-width: 567px) {
    .navbar-modal-container {
        width: 100%;
    }
}

.navbar-modal-search-bar {
    color: var(--color-text);
    border: none;
    font-size: 14px;
    font-weight: 400;
    outline: none;
    background-color: transparent;
    margin: auto 10px;
}

.navbar-modal-search-bar:focus {
    background-color: transparent;
    color: var(--color-text);
    box-shadow: none;
}

.navbar-modal-search-bar-icon {
    color: #03111E;
    margin: auto 0;
    font-size: 12px;
}

.navbar-search-company-container {
    background-color: var(--color-background);
    padding: 7px;
    margin: 5px 0;
    border-radius: 10px;
    display: flex;
    cursor: pointer;
}


.navbar-modal-company-loader-container {
    background-color: var(--color-background);
    padding: 10px;
    margin: 10px 0;
    display: flex;
}


.loader {
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}

/* Standard syntax */
@keyframes circleLoaderColorPalette {
    0% {
        background: var(--loader-primary);
    }
    100% {
        background: var(--loader-secondary)
    }
}

.navbar-searched-circle-loader {
    animation-name: circleLoaderColorPalette;
    border-radius: 50%;
    height: 40px;
    width: 40px;
}

.navbar-searched-pill-loader {
    animation-name: circleLoaderColorPalette;
    border-radius: 5px;
    height: 5px;
    width: 50px;
    margin: auto 10px;
}

.navbar-search-company-logo {
    border-radius: 5px;
    width: 30px;
    height: 30px;
}

.navbar-searched-company-name {
    color: var(--color-text);
    font-size: 12px;
    font-weight: 600;
    margin: auto 10px;
    max-width: 260px;
}

.navbar-searched-company-code {
    color: var(--color-text);
    font-size: 12px;
    font-weight: 400;
    margin: auto 0;
}

.navbar-searched-company-exchange {
    margin: auto 0 auto auto;
    color: var(--color-text);
    font-size: 12px;
    font-weight: 600;
    text-align: right;
}

.no-companies-found-text {
    color: #0C1B29;
    font-size: 12px;
    font-weight: 600;
    margin: 20px 0;
}

.companies-list {
    max-height: 300px;
    overflow-y: auto;
    padding: 0 5px;
    margin: 5px 0;
}

.companies-list::-webkit-scrollbar {
    width: 5px
}

.companies-list::-webkit-scrollbar-thumb {
    background-color: var(--color-scroll);
}

.companies-list::-webkit-scrollbar-track {
    background-color: var(--color-background);
}

@media screen and (max-width: 567px) {
    .companies-list {
        max-height: 350px;
    }

    .navbar-search-company-container {
        padding: 5px;
    }

    .navbar-search-company-logo {
        width: 35px;
        height: 35px;
    }

    .navbar-searched-company-name {
        font-size: 10px;
        width: 170px;
        margin: auto 5px;
    }

    .navbar-searched-company-code {
        font-size: 10px;
    }

    .navbar-searched-company-exchange {
        font-size: 10px;
        font-weight: 400;
    }

}

.MuiFormControlLabel-root {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.nav-subscribe-btn {
    background-color: transparent;
    background-image: linear-gradient(150deg, #2F78DA 0%, #F2295B 100%);
    width: 120px;
    margin: auto 10px;
    color: white;
    font-size: 14px;
    font-weight: 600;
    border-radius: 20px;
    border: 0;
}

.nav-subscribe-btn:hover {
    color: white;
}

@media screen and (max-width: 768px) {
    .nav-subscribe-btn {
        display: none;
    }
}

