.company-detail-tab {
    padding: 5px 0;
    width: 100%;
    border-radius: 10px;
    overflow-x: auto;
}

/*@media screen and (max-width: 768px) {*/
/*    .company-detail-tab {*/
/*        width: calc(100vw - 40px);*/
/*    }*/
/*}*/

.company-detail-tab::-webkit-scrollbar {
    height: 5px
}

.company-detail-tab::-webkit-scrollbar-thumb {
    background-color: var(--color-scroll);
}

.company-detail-tab::-webkit-scrollbar-track {
    background-color: var(--color-foreground)
}

.company-detail-container {
    padding: 15px 20px;
    display: flex;
    width: 100%;
    height: 100%;
    background-color: var(--color-foreground);
}

.etf-detail-container {
    padding: 20px;
    display: flex;
    width: 100%;
    height: 100%;
    background-color: var(--color-foreground);
}

@media screen and (max-width: 1200px) {
    .company-detail-container {
        width: 1200px;
    }
}

@media screen and (max-width: 1450px) {
    .etf-detail-container {
        width: 1400px;
    }
}

.company-detail-name-container {
    display: flex;
    flex-direction: column;
    margin: auto auto auto 0;
}


@media screen and (max-width: 850px) {
    .company-detail-name-container {
        /*margin: auto 50px auto 0;*/
    }
}

@media screen and (max-width: 380px) {
    .company-detail-name-container {
        /*margin: auto 12px auto 0;*/
    }
}

.company-overview-logo {
    width: 50px;
    height: 50px;
    margin: auto 10px;
    border-radius: 5px;
}

.key-facts-logo {
    width: 28px;
    height: 28px;
    margin: 0 10px 0 0;
}

.company-overview-name {
    font-size: 17px;
    font-weight: 700;
    color: var(--color-text);
    /*width: 500px;*/
}

/*@media screen and (max-width: 1450px) {*/
/*    .company-overview-name {*/
/*        font-size: 14px;*/
/*        width: 350px;*/
/*    }*/
/*}*/

.company-overview-meta {
    font-size: 10px;
    font-weight: 400;
    color: var(--color-text);
}

.company-overview-market-info-container {
    display: flex;
    margin-top: 5px;
}

.adjusted-close {
    font-size: 16px;
    font-weight: 700;
    margin: auto 0;
    color: var(--color-text);
}

.adjusted-close-last-available {
    font-size: 11px;
    font-weight: 600;
}

.company-overview-mcap-trading {
    font-size: 12px;
    color: var(--color-text);
    font-weight: 700;
    margin: auto 10px;
}

.analyst-ratings {
    font-size: 14px;
    font-weight: 700;
    color: var(--color-text);
    margin-bottom: 10px;
}

.analyst-progress-container {
    display: flex;
}

.analyst-progress-bar {
    background-color: var(--color-background);
    width: 150px;
    height: 10px;
    border-radius: 5px;
    margin: auto 0;
}

.price-target-analyst-progress-bar {
    width: 100% !important;
}

.analyst-progress-text {
    color: var(--color-text);
    margin: auto 10px;
    font-size: 12px;
    font-weight: 700;
}

.progress-bar {
    border-radius: 5px;
}

.company-overview-top-bar-separator {
    height: 100%;
    width: 1px;
    background-color: #213E44;
    margin: auto;
}

.holding-profile-performance {
    margin: 0 20px 0 30px;
}

.guru-top-bar-separator {
    width: 1px;
    background-color: #213E44;
}

.company-overview-top-bar-info-container {
    padding: 0 20px;
    margin: 0 auto auto auto;
    /*margin: auto;*/
}

.company-overview-top-bar-info-text {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 15px;
    color: var(--color-text);
}

.company-overview-analyst-suggestion {
    border-radius: 10px;
    padding: 5px 10px;
    font-size: 13px;
    font-weight: 600;
    display: flex;

    & p {
        margin: auto
    }
}



.company-overview-top-bar-info-score {
    font-weight: 600;
    font-size: 12px;
    color: var(--color-text);
}

.company-overview-top-bar-index-info {
    font-weight: 400;
    font-size: 10px;
    color: var(--color-text);
}

.company-overview-top-bar-iSqore-text {
    text-align: center;
    font-weight: 700;
    font-size: 14px;
    color: var(--color-text);
}

.company-overview-top-bar-iSqore-value {
    text-align: center;
    font-weight: 700;
    font-size: 12px;
    color: var(--color-text);
}

.iSqore-health-indicator-container {
    display: flex;
    margin-bottom: 2px;
}

.indicator-name {
    color: var(--color-text);
    width: 100px;
    font-size: 13px;
    font-weight: 700;
    margin-left: auto;
}

.indicator-grade {
    font-size: 12px;
    font-weight: 700;
    width: 40px;
    margin-left: 5px;
    color: var(--color-text);
}

.company-overview-table-container {
    padding: 0 5px;
    height: 100%;
}

@media screen and (max-width: 992px) {
    .price-target-performance-table {
        padding: 0;
        margin: 10px 0;
    }
}

@media screen and (max-width: 992px) {
    .company-overview-table-container {
        margin-bottom: 10px;
    }
}

.company-overview-table-title {
    background-color: var(--color-headers);
    padding: 15px;
    border-radius: 10px 10px 0 0;
    width: 100%;
    display: flex;
}

.company-overview-table-title-highlights {
    flex-direction: column;
}

.company-overview-bottom-table-title {
    display: flex;
}

.company-overview-table-title > p {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;
    color: var(--color-text);
}

.company-overview-table-body-container {
    background-color: var(--color-foreground);
    padding: 10px;
    border-radius: 0 0 10px 10px;
}

.isqore-keyfacts-body-container {
    height: 280px;
}

.company-overview-middle-body-container {
    height: 295px;
}

.company-overview-bottom-table-body-container {
    border-radius: 10px;
}

.company-overview-sm-table-header {
    margin: 0;
}

.company-overview-sm-table-body {
    border: none;
    margin-bottom: 0;
}

.earnings-table-heading {
    font-weight: 700;
    font-size: 15px;
    color: var(--color-text);
}

.earnings-table-row {
    display: flex;
    border-bottom: 1px solid #265862;
    padding: 5px 0;
    color: var(--color-text);
}

.company-overview-sm-table-body > thead {
    border-bottom: 2px solid #213E44 !important;
}

.company-overview-sm-table-body > thead > tr > th {
    color: var(--color-text);
    font-size: 14px;
    letter-spacing: 1px;
    border-bottom: 1px solid var(--color-text) !important;
    /*padding: 0;*/
}

.company-overview-sm-table-body > thead > tr > .title {
    width: 200px !important;
    color: var(--color-text);
}

.company-overview-sm-table-body > tbody > tr {
    color: var(--color-text);
    border-bottom: none;
    font-size: 10px;
    /*border-bottom: 0.5px solid #213E44;*/
}

.company-overview-sm-table-body > tbody > tr > td {
    border-bottom: none;
    font-size: 12px;
    padding: 5px !important;
}

.financial-overview-table-container {
    padding-top: 0;
    padding-bottom: 1px;
}

.financial-overview-sm-table-body > thead > tr > th {
    font-size: 12px;
    padding: 4px;
}

.financial-overview-sm-table-body > tbody > tr > td {
    font-size: 12px;
    padding: 4px;
}

.fair-value-sm-table-body > thead > tr > th {
    letter-spacing: 0;
    font-size: 12px;
    width: auto;
}

.fair-value-sm-table-body > tbody > tr > td {
    font-size: 12px;
    padding: 4px !important;
}

.performance-table > tbody > tr > td {
    padding: 3.78px !important;
}

.company-overview-profile-header-container {
    padding: 10px;
    background-color: var(--color-headers);
    border-radius: 5px 5px 0 0;
}

.company-overview-profile-body-container {
    background-color: var(--color-foreground);
    padding: 20px;
    border-radius: 0 0 5px 5px;
}

.company-overview-profile-header-text {
    font-size: 17px;
    font-weight: 700;
    color: var(--color-text);
}

.company-overview-profile-point-text {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 3px;
    color: var(--color-text);
}

.company-overview-profile-point-link {
    font-size: 14px;
    margin-bottom: 3px;
}

.company-overview-profile-description-heading {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 3px;
    color: var(--color-text);
}

.company-overview-profile-description-text {
    font-size: 12px;
    font-weight: 500;
    color: var(--color-text);
}

.company-overview-financial-table {
    table-layout: fixed;
    width: 100%;
}

.company-overview-financial-table > thead > tr > th {
    width: 100px;
}

.company-overview-financial-table > thead > tr > .company-overview-financial-table-field-names {
    width: 240px;
}

.financial-table-block-no-style {
    background-color: var(--color-background) !important;
    border: none !important;
    width: 5px !important;
}

.company-overview-financial-table > thead > tr > .company-overview-financial-table-field-names-fair-value {
    width: 160px;
}

.company-overview-fair-value-table {
    margin-bottom: 0;
}

.financial-control-container {
    display: flex;
    width: 100%;
}

.financial-control-inner-container {
    display: flex;
    /*border: 1px solid var(--color-border);*/
    border-radius: 5px;
    padding: 2px;
}

.financial-control-buttons {
    min-height: 30px;
    font-size: 12px;
    font-weight: 400;
    border-radius: 5px;
    padding: 0 15px;
    color: var(--color-text);
    background-color: var(--color-headers);
    cursor: pointer;
    display: flex;
}

.financial-control-buttons > p {
    margin: auto;
}

.currency-info-text {
    font-size: 12px;
    font-weight: 400;
    color: var(--color-text);
}

.currency-selector-financials {
    height: 37px;
    width: 140px;
    font-size: 12px;
    background-color: #1BA2DA;
    color: white;
    margin-left: 10px;
}

.financial-control-buttons-separator {
    width: 1px;
    background-color: #213E44;
    height: 100%;
    margin: 0 8px;
}

@media screen and (max-width: 375px) {
    .financial-control-buttons {
        font-size: 10px;
    }

    .financial-control-buttons-separator {
        margin: 0 3px;
    }

    .currency-selector-financials {
        font-size: 12px;
        margin-left: 3px;
    }
}

.financial-control-buttons-active {
    color: white;
    background-color: #1BA2DA;
}


.company-overview-dividend-header-container {
    margin: 0 0 5px 0;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.company-overview-dividend-header-container-second {
    padding: 0 0 0 4px;
}

@media screen and (max-width: 986px) {
    .company-overview-dividend-header-container-second {
        padding: 0;
    }
}

.company-overview-dividend-header {
    padding: 10px 15px;
    background-color: var(--color-headers);
    border-radius: 5px 5px 0 0;
}

.company-overview-dividend-header > p {
    color: var(--color-text);
    font-size: 14px;
    font-weight: 600;
}

.company-overview-dividend-header-body {
    padding: 5px;
    background-color: var(--color-foreground);
    border-radius: 0 0 5px 5px;
    height: 122.5px;
    display: flex;
    flex-wrap: wrap;
}

@media screen and (max-width: 992px) {
    .company-overview-dividend-header-body {
        height: 100%;
        margin-bottom: 5px;
    }
}

.company-overview-dividend-header-body-point {
    font-size: 10.5px;
    padding: 3px 7px;
    border-radius: 10px;
    height: 23px;
    display: flex;
    margin-bottom: 5px;
    background-color: var(--color-background);
    color: var(--color-text);
    border: 1px solid var(--color-headers);

    & svg {
        margin: auto 5px auto 0;
        font-size: 15px;
    }

    .check {
        font-size: 13px;
    }

    & p {
        margin: auto 0;
    }
}

.company-overview-key-facts-point {
    margin: 0 5px 5px 0;
}

@media screen and (max-width: 1200px) {
    .company-overview-dividend-header-body-point {
        margin: 5px
    }
}

.company-overview-grade {
    font-size: 20px;
    font-weight: 700;
    margin: 0 0 10px 0;
    color: var(--color-text);
}

.company-overview-rating-table {
    table-layout: fixed;
    width: 100%;
}

.company-overview-rating-table > thead > tr > th {
    width: 80px;
}

.company-overview-rating-table > thead > tr > .company-overview-meta-column {
    width: 50px;
}

.company-overview-rating-table > thead > tr > .investor-name {
    width: 170px;
}

.price-target-company-logo {
    width: 25px;
    height: 25px;
    margin-right: 10px;
    border-radius: 5px;
}

.price-targets-table > thead > tr > .market-overview-price-target-date {
    min-width: 90px;
}

.price-targets-table > thead > tr > .market-overview-price-target-symbol {
    min-width: 130px;
}

.price-targets-table > thead > tr > .market-overview-price-target-name {
    min-width: 200px;
}

.price-targets-table > thead > tr > .market-overview-price-target-market-cap {
    min-width: 150px
}

.price-targets-table > thead > tr > .market-overview-price-target-analyst-company {
    min-width: 160px
}

.price-targets-table > thead > tr > .market-overview-price-target-last-close {
    min-width: 120px
}

.price-targets-table > thead > tr > .market-overview-price-target-article {
    min-width: 100px;
}

.price-targets-table > thead > tr > .market-overview-price-target-potential {
    min-width: 120px;
}

.financial-overview-tabs-bar {
    min-width: calc(100vw - 140px);
    padding: 10px 0;
    overflow-x: auto;
    display: flex;
}

.fair-value-tabs-bar {
    padding: 0 0 10px 0;
    min-width: 300px;
}

.financial-overview-tabs-bar::-webkit-scrollbar {
    height: 5px;
}

.financial-overview-tabs-bar::-webkit-scrollbar-thumb {
    background-color: var(--color-scroll);
}

.financial-overview-tabs-bar::-webkit-scrollbar-track {
    background-color: var(--color-foreground)
}

.financial-overview-bar-tab {
    min-width: 155px;
    height: 140px;
    border-radius: 10px;
    padding: 10px;
    background-color: var(--color-foreground);
    margin: auto 10px auto 0;
}

.fair-overview-bar-tab {
    /*width: 100%;*/
    height: 100px;
}

@media screen and (max-width: 992px) {
    .dividend-bar-tab {
        margin: 10px 0 !important;
    }
}

.valuation-tabs {
    height: 70px;
    padding: 10px 30px;

    .financial-overview-bar-per-container-meta {
        font-size: 12px;
    }

    .financial-overview-bar-tab-text {
        font-size: 15px;
    }

    .financial-overview-bar-per-container {
        font-size: 12px;
    }
}

.dividend-tabs {
    margin: auto 5px;
    padding: 10px;
}

@media screen and (max-width: 1200px) {
    .dividend-tabs {
        margin: 5px;
    }
}

@media screen and (max-width: 992px) {
    .valuation-tabs {
        padding: 10px 20px;
    }
}

.financial-overview-bar-tab-number {
    font-size: 15px;
    font-weight: 600;
    text-align: center;
    color: var(--color-text);
}

.financial-overview-bar-tab-text {
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    color: var(--color-text);
}

.financial-overview-bar-container {
    width: 100%;
    display: flex;
    margin: 5px 0;
}

.financial-overview-bar-per-container-meta {
    font-size: 10px;
    color: var(--color-text);
    margin: auto auto auto 0;
}

.financial-overview-bar-per-container-none {
    color: var(--color-text);
}

.financial-overview-bar-per-container {
    padding: 3px 5px;
    border-radius: 5px;
    font-size: 10px;
    font-weight: 600;
    text-align: center;
}

.financial-overview-bar-tab-separator {
    height: 130px;
    min-width: 2px;
    border-radius: 10px;
    background-color: var(--color-border);
    margin: auto 10px auto 0;
}

.fair-value-bar-tab-separator {
    height: 90px;
}

.per_container {
    font-size: 11px;
    width: 60px;
    margin: 0 auto 0 0;
    padding: 2px;
    border-radius: 5px;
    text-align: center;
    font-weight: 600;
}

.dividend-table {
    & th {
        font-weight: 600 !important;
    }

    .dividend-table-arrow {
        font-family: 'Montserrat', 'Poppins', sans-serif !important;
        min-width: 15px !important;
        max-width: 15px !important;
        width: 15px !important;

        & svg {
            color: var(--color-text);
        }
    }

    .dividend-table-date {
        font-family: 'Montserrat', 'Poppins', sans-serif !important;
        min-width: 50px !important;
        max-width: 50px !important;
        width: 50px !important;
    }

    .dividend-table-payment-date {
        font-family: 'Montserrat', 'Poppins', sans-serif !important;
        min-width: 50px !important;
        max-width: 50px !important;
        width: 50px !important;
    }

    .dividend-table-dividend {
        font-family: 'Montserrat', 'Poppins', sans-serif !important;
        min-width: 50px !important;
        max-width: 50px !important;
        width: 50px;
    }

    .dividend-table-growth {
        min-width: 50px !important;
        max-width: 50px !important;
        width: 50px;
    }

    .dividend-table-yield {
        min-width: 50px !important;
        max-width: 50px !important;
        width: 50px;
    }

    .dividend-table-open {
        background-color: var(--color-headers);
    }

    .dividend-table-close {
        background-color: var(--color-foreground);
    }
}
