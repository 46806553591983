.landing-page-container {
    position: relative;
    overflow-x: hidden;
    background-color: #03111E;
    z-index: 1;
}

.landing-page {
    height: 100vh;
    width: 100vw;
    z-index: 3;
    position: relative;
}

.navbar-header {
    height: 100%;
}

.landing-page-header {
    height: 100vh;
}

.landing-page-navbar {
    width: 100%;
    display: flex;
    height: 80px;

    & ul {
        width: 100%;
        list-style: none;
        display: flex;
        margin: 0;
        padding: 0;

        & li {
            margin: auto 0 auto 20px;

            & a {
                font-size: 14px;
                font-weight: 600;
            }
        }
    }
}

.landing-page-navbar-img-link {
    display: block;
}

.landing-page-navbar-img {
    margin: auto auto auto 0;
    width: 150px;
}

.landing-page-navbar-img-sm-link {
    display: none;
}

.landing-page-navbar-img-sm {
    margin: auto auto auto 0;
    width: 30px;
}

.landing-page-section-h1 {
    text-align: center;
    color: white;
    font-size: 44px;
    font-weight: 600;
    margin-bottom: 20px;
}

@media screen and (max-width: 786px) {
    .landing-page-header {
        height: 80vh;
    }

    .landing-page-navbar {
        padding: 0 10px;
        height: 55px;

        & ul {
            & li {
                & a {
                    font-size: 11px;
                    margin: auto 0 auto 4px;
                }
            }
        }
    }

    .landing-page-section-h1 {
        font-size: 36px;
    }

    .landing-page-navbar-img-link {
        display: none;
    }

    .landing-page-navbar-img-sm-link {
        display: block;
    }
}

@media screen and (max-width: 567px) {
    .landing-page-navbar {
        padding: 0 10px;
        height: 55px;

        & ul {
            & li {
                font-size: 11px;
                margin: auto 0 auto 7px;
            }
        }
    }

    .landing-page-section-h1 {
        font-size: 24px;
    }
}

.landing-page-navbar-link {
    cursor: pointer;
    color: #1BA2DA;
    text-decoration: none;
}

.landing-page-navbar-link:hover {
    color: #1BA2DA;
}

.landing-page-navbar-btn {
    color: black;
}

.landing-page-navbar-btn:hover {
    color: white;
}

.landing-page-laptop {
    width: 60%;
    /*height: 100%;*/
    margin: auto;
}

.glow-image {
    position: relative;
    display: inline-block;
    box-shadow: 0 0 20px rgba(255, 255, 255, 0.3);
}

@media screen and (max-width: 1200px) {
    .landing-page-laptop {
        margin-top: 10px;
        width: 50%;
    }
}

@media screen and (max-width: 991px) {
    .landing-page-text {
        font-size: 12px !important;
    }

    .landing-page-laptop {
        margin-top: 10px;
        width: 70%;
    }
}

@media screen and (max-width: 767px) {
    .landing-page-laptop {
        margin-top: 10px;
        width: 85%;
    }
}

@media screen and (max-width: 567px) {
    .landing-page-text {
        display: none;
    }

    .landing-page-laptop {
        margin-top: 10px;
        width: 100%;
    }
}

.landing-page-text-heading {
    font-weight: 700;
    font-size: 48px;
    color: #FFFFFF;
}

.landing-page-text {
    font-size: 14px;
    color: #FFFFFF;
}

.landing-page-search {
    background-color: white;
}

.landing-page-content-container {
    width: 100%;
    height: calc(100vh - 230px);
    display: flex;
}

.landing-page-content-inner-container {
    margin: auto;
}

.landing-page-search-container {
    margin: 20px 0;
}

@media screen and (max-width: 567px) {
    .landing-page-text-heading {
        font-size: 24px;
    }

    .landing-page-text {
        font-size: 12px;
    }

    .landing-page-content-container {
        height: calc(100vh - 230px);
    }

    .landing-page-search-container {
        margin: 10px 0;
    }
}

.landing-page-footer-container {
    /*height: 20px;*/
    border-top: 2px solid #0C1B29;
    padding: 10px;
}

.landing-page-footer-text {
    font-size: 12px;
    text-align: center;
    color: #FFFFFF;
}

.landing-page-footer-link {
    color: #FFFFFF;
    text-decoration: none;
}

.landing-page-footer-link:hover {
    color: #FFFFFF;
}

@media screen and (max-width: 991px) {
    .landing-page-footer-text {
        font-size: 11px;
    }
}

@media screen and (max-width: 567px) {
    .landing-page-footer-container {
        border-top: 2px solid #0C1B29;
        padding: 10px;
    }

    .landing-page-footer-text {
        font-size: 10px;
    }
}

.landing-page-search-bar-container {
    width: 100%;
    display: flex;
    border-radius: 20px;
    padding: 12px 20px;
    border: 1px solid #265862;
    cursor: pointer;
    background-color: #03111E;
}

.landing-page-section {
    height: calc(100vh - 80px);
    width: 100%;
    display: flex;
    padding: 10px;
}

.landing-page-section-full-page {
    width: 100%;
    display: flex;
    margin-bottom: 130px;

    .landing-page-section-div {
        margin: auto;
        /*max-width: 1200px;*/
    }
}

@media screen and (max-width: 786px) {
    .landing-page-section {
        height: calc(80vh - 55px);
    }

    .landing-page-section-full-page {
        margin-bottom: 80px;
    }
}

.landing-page-section-h2 {
    text-align: center;
    color: white;
    font-size: 40px;
    font-weight: 600;
    margin-bottom: 20px;
}

.landing-page-section-h3 {
    text-align: center;
    color: #969696;
    font-size: 17px;
    margin-bottom: 40px;
}

.landing-page-section-image-container {
    display: flex;
    width: 100%;
}

@media screen and (max-width: 786px) {
    /*.landing-page-section-full-page {*/
    /*    min-height: 75vh;*/
    /*}*/
    .landing-page-section-h2 {
        font-size: 28px;
    }

    .landing-page-section-h3 {
        font-size: 13px;
    }

}

.landing-page-footer {
    display: flex;
    flex-direction: column;
    padding: 10px 0;
    font-size: 13px;
    color: white;
    text-align: center;

    & a {
        text-decoration: none;
        color: white;
        font-weight: 600;
    }
}
