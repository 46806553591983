.package-type-change-handler {
    display: flex;
}

.payment-package-type {
    border: 2px solid var(--color-border);
    color: #706F71;
    font-weight: 700;
    font-size: 17px;
    padding: 5px 15px;
    cursor: pointer;
}

.payment-package-left {
    border-radius: 15px 0 0 15px;
}

.payment-package-right {
    border-radius: 0 15px 15px 0;
}

.payment-package-active-type {
    background-color: var(--color-selected);
    color: #1BA1DA;
}

.payment-explanation-text {
    color: white;
    text-align: right;
    font-weight: 800;
    font-size: 42px;
    line-height: 50px;
}

@media screen and (max-width: 768px) {
    .payment-explanation-text {
        text-align: left;
    }
}

.package-column {
    width: 100%;
    background-color: #F5F6FA;
    padding: 40px;
    border-radius: 20px;
}

.payment-package-name {
    font-size: 24px;
    text-align: center;
    font-weight: 600;
    color: #0C1B29;
}

.payment-package-description {
    font-size: 14px;
    text-align: center;
    font-weight: 500;
    color: #0C1B29;
}

.package-price {
    font-size: 64px;
    text-align: center;
    font-weight: 700;
    color: #0C1B29;
}

.package-price-subtext {
    font-size: 24px;
}

.tick {
    color: #3BDCC4;
    font-weight: 700;
}

.package-list-item {
    font-weight: 700;
    font-size: 14px;
    color: #0C1B29;
}


.payment-modal {
    width: 370px;
    background-color: white;
}

.payment-modal-card {
    width: 800px;
    max-height: 550px;
    background-color: white;
}

@media screen and (max-width: 820px) {
    .payment-modal-card {
        overflow-y: auto;
        width: 700px;
    }
}

@media screen and (max-width: 700px) {
    .payment-modal-card {
        width: 500px;
    }
}

@media screen and (max-width: 567px) {
    .payment-modal-card {
        width: 370px;
    }
}

.payment-method-container {
    padding: 5px 10px;
    border-radius: 10px;
    border: 1px solid #5E5E5E;
    display: flex;
}

/*.paypal-button-number-1 {*/
/*    display: none !important;*/
/*}*/

.payment-type-selected {
    background-color: transparent;
    background-image: linear-gradient(150deg, #2F78DA 0%, #F2295B 100%);
    color: white;
}

.payment-type-selected > p {
    color: white;
}

.payment-info-container {
    padding: 0 10px;
    height: 100%;
    width: 100%;
    display: flex;
}

.payment-info-text-container {
    margin: 20px 0;
}

.payment-info-text {
    font-size: 12px;
    font-weight: 500;
    color: #5E5E5E;
}

.payment-method-cards {
    font-size: 30px;
    margin: auto 0 auto auto;
}

.payment-input-field {
    font-size: 12px;
    font-weight: 400;
}

.payment-btn {
    border-radius: 5px;
    width: 100%;
    display: flex;
    background-color: transparent;
    background-image: linear-gradient(150deg, #2F78DA 0%, #F2295B 100%);
    color: white;
    font-weight: 600;
    font-size: 14px;
    padding: 10px;
    cursor: pointer;
}

.payment-select-field {
    font-size: 12px;
}

.payment-method-helper-text {
    font-size: 14px;
    font-weight: 600;
    color: #5E5E5E;
    margin: auto 0;
}

.payment-info-heading {
    font-size: 15px;
    color: #5E5E5E;
    font-weight: 600;
}

.payment-info-header {
    display: flex;
}

.payment-method-container:hover {
    background-color: #E9ECEF;
    cursor: pointer;
}

/*@media screen and (max-width: 567px) {*/
/*    .payment-method-container:hover {*/
/*        font-size: 12px;*/
/*    }*/
/*}*/

.selected-package-name {
    font-size: 24px;
    font-weight: 600;
    color: #3E3E3E;
}

.selected-package-price {
    font-size: 30px;
    font-weight: 600;
    color: #3E3E3E;
    margin-left: auto;
}

.selected-package-description {
    font-size: 12px;
    font-weight: 400;
    color: #3E3E3E;
}

.selected-package-price-subtext {
    font-size: 10px;
    font-weight: 700;
    color: #3E3E3E;
}

.selected-package-list-item {
    font-weight: 600;
    font-size: 12px;
    color: #3E3E3E;
}
