.watch-headings {
    font-size: 17px;
    font-weight: 700;
    margin-bottom: 5px;
    color: var(--color-text);
}

.add-watchlist-btn {
    color: #4ebe96;
    border: 1px solid #4ebe96;
    font-size: 14px;
    font-weight: 700;
    width: 300px;
}

.add-watchlist-btn:hover {
    color: white;
    background-color: #4ebe96;
}

.add-watchlist-btn-lg {
    display: block;
}

.add-watchlist-btn-sm {
    display: none;
}

@media screen and (max-width: 1200px) {

    .add-watchlist-btn-sm {
        display: block;
    }

    .add-watchlist-btn {
        font-size: 10px;
        width: 200px;
    }
}

.save-watchlist-btn {
    color: #4ebe96;
    border: 1px solid #4ebe96;
    font-size: 14px;
    font-weight: 700;
}

.save-watchlist-btn:hover {
    color: white;
    background-color: #4ebe96;
}

.watchlist-tabs-container > .MuiBox-root > .MuiTabs-root > .MuiTabs-scrollable > .MuiTabs-flexContainer > .MuiButtonBase-root {
    text-transform: none;
    font-size: 14px;
}

.watchlist-tabs-container > .MuiBox-root > .MuiTabs-root > .MuiTabs-scrollable > .MuiTabs-flexContainer > .MuiButtonBase-root > .MuiTab-wrapper {
    color: var(--color-text) !important;
}

.watchlist-tabs-container > .MuiBox-root > .MuiTabs-root > .MuiButtonBase-root > .MuiSvgIcon-fontSizeSmall {
    color: var(--color-text) !important;
}

.watchlist-lg-table {
    table-layout: fixed;
    width: 100%;
}

.watchlist-lg-table > thead > tr > th {
    width: 120px !important;
    /*word-wrap: break-word;*/
}

.watchlist-lg-table > thead > tr > .watchlist-table-index {
    width: 100px !important
}

.watchlist-lg-table > thead > tr > .watchlist-table-ticker-name {
    width: 170px !important
}

.watchlist-lg-table > thead > tr > .watchlist-table-delete {
    width: 25px !important;
}

.watchlist-lg-table > thead > tr > .watchlist-table-sector {
    width: 150px !important
}

.watchlist-lg-table > thead > tr > .watchlist-table-industry {
    width: 180px !important
}

.watchlist-lg-table > thead > tr > .watchlist-table-marketcap {
    width: 140px !important
}

.watchlist-lg-table > thead > tr > .watchlist-isqore-th {
    width: 100px !important
}

.screener-lg-table > thead > tr > .screener-watchlist {
    width: 30px !important;
}

.screener-lg-table > thead > tr > .screener-ticker {
    width: 100px !important;
}

.screener-lg-table > thead > tr > .screener-company-name {
    width: 200px !important;
}

.screener-lg-table > thead > tr > .screener-company-industry {
    width: 190px !important;
}

.screener-lg-table > thead > tr > .screener-analyst-potential {
    width: 150px !important;
}


.watchlist-modal-body {
    width: 450px;
    background-color: var(--color-foreground);
    padding: 10px;
    border-radius: 5px;
    position: absolute;
}

.watchlist-modal-title {
    font-size: 17px;
    font-weight: 700;
    color: var(--color-text);
    margin-bottom: 10px;
}

.watchlist-input-field {
    background-color: var(--color-background);
    color: var(--color-text);
    font-size: 14px;
    border: 1px solid var(--color-border);
}

.watchlist-input-field:focus {
    background-color: var(--color-background);
    color: var(--color-text);
}

.delete-watchlist-ticker {
    color: var(--color-text);
    font-size: 17px;
    cursor: pointer;
}

.add-ticker-btn {
    color: #4ebe96;
    border: 1px solid #4ebe96;
    font-size: 12px;
    font-weight: 700;
    width: 120px;
    margin: 0 20px 0 0;
}

.add-ticker-btn:hover {
    color: white;
    background-color: #4ebe96;
}

.settings-ticker-btn {
    color: var(--color-text);
    border: 1px solid var(--color-text);
    font-size: 12px;
    font-weight: 600;
}

.settings-ticker-btn:hover {
    color: var(--color-background);
    background-color: var(--color-text);
}

.delete-ticker-btn {
    color: #bd1717;
    border: 1px solid #bd1717;
    font-size: 12px;
    font-weight: 600;
}

.delete-ticker-btn:hover {
    color: white;
    background-color: #bd1717;
}

@media screen and (max-width: 567px) {
    .add-ticker-btn {
        font-size: 8px;
        width: 100%;
    }

    .settings-ticker-btn {
        font-size: 8px;
        width: 100%;
    }

    .delete-ticker-btn {
        font-size: 8px;
        width: 100%;
    }
}

.ticker-modal-body {
    width: 600px;
    padding: 10px;
    border-radius: 5px;
    position: absolute;
    background-color: var(--color-foreground);
}

@media screen and (max-width: 567px) {
    .ticker-modal-body {
        width: 370px;
    }
}

.ticker-modal-title {
    font-size: 20px;
    font-weight: 700;
    color: var(--color-text);
    margin-bottom: 10px;
}

.ticker-modal-search-modal {
    background-color: var(--color-background);
    color: var(--color-text);
    font-size: 14px;
    border: 1px solid var(--color-border);
    margin-bottom: 10px;
}

.ticker-modal-search-modal:focus {
    background-color: var(--color-background);
    color: var(--color-text);
}

.ticker-container {
    display: flex;
    font-size: 12px;
    font-weight: 700;
    padding: 15px;
    cursor: pointer;
    color: var(--color-text);
    background-color: var(--color-background);
    border-radius: 10px;
    margin-bottom: 12px;
}

.watchlist-settings-modal-body {
    width: 450px;
    background-color: var(--color-foreground);
    padding: 10px;
    border-radius: 5px;
    position: absolute;
}

.watchlist-settings-modal-heading {
    font-size: 17px;
    font-weight: 700;
    color: var(--color-text);
    margin-bottom: 10px;
}

.ticker-modal-back-btn {
    font-weight: 600;
    font-size: 12px;
}

.ticker-modal-save-btn {
    color: #4ebe96;
    border: 1px solid #4ebe96;
    font-size: 12px;
    font-weight: 600;
    margin-left: auto;
}

.ticker-modal-save-btn:hover {
    color: white;
    background-color: #4ebe96;
}

.watchlist-star {
    color: #1BA2DA;
    margin-left: 10%;
    font-size: 17px;
}

.td-per-change {
    display: flex;
}

.screener-company-name > a {
    width: 100%;
    height: 100%;
    display:block;
    text-decoration: none;
    color: var(--color-text);
}
