.price-target-info-container {
    width: 100%;
    height: 100%;
}

.price-target-info-title-container {
    background-color: var(--color-headers);
    padding: 15px 10px;
    border-radius: 10px 10px 0 0;
}

.price-target-info-title {
    color: var(--color-text);
    font-weight: 700;
    font-size: 15px;
}

.price-target-info-description-container {
    padding: 10px;
    background-color: var(--color-foreground);
    height: 84.5%;
    border-radius: 0 0 10px 10px;
}

.price-target-info-description {
    font-size: 14px;
    color: var(--color-text);
}

.price-target-display-container-xl {
    height: 100%;
    display: block;
}

.price-target-display-container-lg {
    /*height: 100%;*/
    display: none;
}

@media screen and (max-width: 1200px) {
    .price-target-display-container-xl {
        display: none;
    }

    .price-target-display-container-lg {
        display: block;
    }
}

.price-target-info-outer-container {
    padding: 0 0 0 5px;
    display: flex;
    flex-direction: column;
    height: 100%;
}

@media screen and (max-width: 1200px) {
    .price-target-info-description-container {
        height: 81.9%;
    }

    .price-target-info-outer-container {
        padding: 0;
        margin-top: 10px;
    }
}

@media screen and (max-width: 992px) {
    .price-target-info-description-container {
        height: auto;
    }
}

.price-target-highlights-outer-container {
    display: flex;
    margin-bottom: 10px;
}

.price-target-highlights-container {
    width: 40%;
    text-align: center;
    margin: auto 10px;
}

.price-target-highlights-price {
    font-size: 32px;
    font-weight: 700;
    color: var(--color-text);
    white-space: nowrap;
}

.price-target-highlights-upside {
    font-size: 15px;
    font-weight: 700;
    color: var(--color-text);
}


.price-target-analyst-rating {
    width: 60%;
}

@media screen and (max-width: 768px) {

    .price-target-highlights-outer-container {
        display: flex;
        margin: 10px 0;
    }

    .price-target-highlights-container {
        min-width: 120px;
        margin: auto 0;
    }

    .price-target-highlights-price {
        font-size: 16px;
    }

    .price-target-highlights-upside {
        font-size: 12px;
    }

    .price-target-analyst-rating {
        width: 80%;
    }
}


.price-target-analyst-progress-text {
    width: 100px;
}

.price-target-chart-container {
    padding: 0 5px 0 0;
    height: 100%;
}

.price-target-chart-inner-container {
    width: 100%;
    background-color: var(--color-foreground);
    border-radius: 10px;
    padding: 5px 5px 0 0;
    height: 100%;
}

@media screen and (max-width: 1200px) {
    .price-target-chart-container {
        margin: 10px 0 0 0;
    }

    .price-target-chart-inner-container {
        height: 300px;
    }
}

@media screen and (max-width: 992px) {
    .price-target-chart-container {
        padding: 0;
    }

    .price-target-chart-container {
        margin: 0;
    }
}

.dividend-chart-inner-container {
    width: 100%;
    background-color: var(--color-foreground);
    border-radius: 5px;
    padding: 5px 5px 0 0;
    height: 350px;
}

.earnings-bar-graph-chart-container {
    padding: 0 5px 0 0;
    /*padding: 0;*/
}

@media screen and (max-width: 992px) {
    .earnings-bar-graph-chart-container {
        padding: 0;
    }
}

.price-target-chart-inner-container-2 {
    width: 100%;
    background-color: var(--color-foreground);
    border-radius: 15px;
    padding: 10px 0;
    height: 210px
}

.financial-outlook-chart-container {
    padding: 0 5px 0 0;
    height: 100%;
}

.financial-outlook-chart-inner-container {
    width: 100%;
    background-color: var(--color-foreground);
    border-radius: 10px;
    padding: 10px 10px 0 0;
    height: 340px;
}

.financial-outlook-valuation {
    padding: 0 5px;
}

@media screen and (max-width: 1200px) {
    .financial-outlook-valuation {
        margin-top: 10px;
    }
}

@media screen and (max-width: 992px) {
    .financial-outlook-chart-container {
        padding: 0;
        margin-bottom: 0;
    }

    .financial-outlook-valuation {
        padding: 0;
    }
}

.donut-chart-inner-container {
    width: 100%;
    background-color: var(--color-foreground);
    border-radius: 5px;
    padding: 10px 10px 0 0;
    height: 163px;
}

.donut-chart-holding-inner-container {
    width: 100%;
    height: 85%;
    background-color: var(--color-foreground);
    border-radius: 0 0 10px 10px;
    /*padding: 10px 10px 0 0;*/
}

@media screen and (max-width: 992px) {
    .donut-chart-holding-inner-container {
        height: 400px;
    }
}

@media screen and (max-width: 768px) {
    .donut-chart-holding-inner-container {
        height: 400px;
    }
}

.price-target-table {
    table-layout: fixed;
    width: 100%;
    overflow-x: auto;
}

.price-target-table > thead > tr > th {
    width: 100px !important;
}

.price-target-table > thead > tr > .price-target-date {
    width: 50px !important;
}

.price-target-table > thead > tr > .price-target-days-ago {
    width: 80px !important;
}

.price-target-table > thead > tr > .price-target-published {
    width: 150px !important;
}

.price-target-table > thead > tr > .price-target-article {
    width: 120px !important;
}

.price-target-table > thead > tr > .price-target-analyst {
    width: 150px !important;
}

.price-target-table > thead > tr > .price-target-potential {
    width: 150px !important;
}

@media screen and (max-width: 768px) {
    .price-target-table > thead > tr > .price-target-date {
        width: 80px !important;
    }

    .price-target-table > thead > tr > .price-target-days-ago {
        width: 100px !important;
    }
}
