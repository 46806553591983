html {
    --color-text: #0C1B29;

    --color-selected-text: #0C1B29;
    --color-unselected-text: #9d9d9d;

    --color-foreground: #F5F6FA;
    --color-background: #FFFFFF;
    --color-disabled-background: #e1e1e1;
    --color-headers: #e8e8e8;

    --color-unselected-background: #FFFFFF;
    --color-selected-background: #0C1B29;

    --color-scroll: #265862;
    --color-track: #FFFFFF;

    --color-border: #265862;
    --color-selected: #0C1B29;

    --loader-primary: #a6a6a6;
    --loader-secondary: #d2d2d2;
}

html[data-theme="dark"] {
    --color-text: #FFFFFF;

    --color-selected-text: #FFFFFF;
    --color-unselected-text: #9498a8;

    --color-foreground: #0C1B29;
    --color-background: #03111E;
    --color-disabled-background: #000000;
    --color-headers: #132A3F;

    --color-unselected-background: #0C1B29;
    --color-selected-background: #FFFFFF;

    --color-scroll: #265862;
    --color-track: #FFFFFF;

    --color-border: #265862;
    --color-selected: #F5F6FA;

    --loader-primary: #0C1B29;
    --loader-secondary: #11263b;
}

p {
    margin: 0;
    padding: 0;
}

.color-text {
    color: var(--color-text);
}

.fg-color {
    background-color: var(--color-foreground);
}

.bg-color {
    background-color: var(--color-background);
}

body {
    margin: 0;
    color: var(--color-text);
    font-family: 'Montserrat', 'Poppins', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    /*scrollbar-width: inherit  !* For Firefox *!*/
    /*scrollbar-color: var(--color-scroll) var(--color-headers);*/
}

/*::-webkit-scrollbar-thumb {*/
/*    background-color: var(--color-scroll) !important;*/
/*}*/

/*::-webkit-scrollbar {*/
/*    width: 7px !important;*/
/*}*/

/*::-webkit-scrollbar-thumb {*/
/*    background-color: var(--color-scroll);*/
/*}*/

::-webkit-scrollbar-track {
    background-color: var(--color-headers);
}

.soon-available-layout {
    width: 100vw;
    height: 100vh;
    background-color: #222222;
}

.soon-available-layout-body {
    display: flex;
    width: 100%;
    height: 100%;
}

.soon-available-layout-body-content {
    margin: auto;
    display: flex;
    flex-direction: column;
}

.soon-available-layout-body-content-title {
    margin: 20px auto;
    font-size: 35px;
    font-weight: 700;
    color: white;
}

.soon-available-layout-body-content-img {
    width: 100px;
    height: 100px;
    margin: 20px auto;
}

.soon-available-layout-body-content-link {
    margin: 20px auto;
    font-size: 25px;
    font-weight: 700;
    cursor: pointer;
    color: white;
}

@media screen and (max-width: 1200px) {
    .register-heading-text {
        color: #1BA2DA;
        font-size: 20px;
    }

    .register-heading-main {
        font-size: 48px;
        font-weight: 700;
    }
}

@media screen and (max-width: 992px) {
    .register-heading-text {
        color: #1BA2DA;
        font-size: 17px;
    }

    .register-heading-main {
        font-size: 36px;
        font-weight: 700;
    }


}


.tabsContainer {
    width: calc(100vw - 120px);
    overflow-x: auto;
}

.tabsContainer::-webkit-scrollbar {
    height: 5px;
}

.tabsContainer::-webkit-scrollbar-thumb {
    background-color: #132A3F;
}

.tabsContainer::-webkit-scrollbar-track {
    background-color: #060C23;
}

.tabsPanelContainer::-webkit-scrollbar {
    height: 5px;
}

.tabsPanelContainer::-webkit-scrollbar-thumb {
    background-color: #132A3F;
}

.tabsPanelContainer::-webkit-scrollbar-track {
    background-color: #060C23;
}

.tabsContainer > .MuiBox-root > .MuiTabs-root > .MuiTabs-scrollable > .MuiTabs-flexContainer > .MuiButtonBase-root {
    text-transform: none;
    font-size: 14px;
}

.tabsContainer.dark > .MuiBox-root > .MuiTabs-root > .MuiTabs-scrollable > .MuiTabs-flexContainer > .MuiButtonBase-root > .MuiTab-wrapper {
    color: #ffffff !important;
}

.tabsContainer.light > .MuiBox-root > .MuiTabs-root > .MuiTabs-scrollable > .MuiTabs-flexContainer > .MuiButtonBase-root > .MuiTab-wrapper {
    color: black !important;
}

.tabsContainer.dark > .MuiBox-root > .MuiTabs-root > .MuiTabs-scrollable > .MuiTabs-flexContainer > .MuiButtonBase-root > .MuiTab-wrapper {
    color: #ffffff !important;
}

.tabsContainer.dark > .MuiBox-root > .MuiTabs-root > .MuiButtonBase-root > .MuiSvgIcon-fontSizeSmall {
    color: white;
}

.tabsContainer.light > .MuiBox-root > .MuiTabs-root > .MuiButtonBase-root > .MuiSvgIcon-fontSizeSmall {
    color: black;
}

.tabsContainer.light > .MuiBox-root > .MuiTabs-root > .MuiTabs-scrollable > .MuiTabs-flexContainer > .MuiButtonBase-root > .MuiTab-wrapper {
    color: black !important;
}

.MuiSvgIcon-root {
    color: #ffffff;
}

.MuiBox-root {
    padding: 3px 0 0 0 !important;
}

/* Navbar */


.dark-background {
    background-color: #03111E;
}

.light-background {
    background-color: #FFFFFF;
}

.dark-color {
    color: #FFFFFF;
}

.light-color {
    color: #0C1B29;
}

.component-dark-background {
    background-color: #0C1B29;
}

.component-light-background {
    background-color: #F5F6FA;
}

.dashboard-body::-webkit-scrollbar {
    width: 10px;
}

.dashboard-body::-webkit-scrollbar-thumb {
    background-color: var(--color-scroll)
}

.dashboard-body::-webkit-scrollbar-track {
    background-color: var(--color-headers);
}

.dashboard-body {
    padding: 20px;
    margin-left: 70px;
    width: 100%;
    background-color: var(--color-background);
    height: calc(100vh - 65px);
    overflow-x: auto !important;
}


@media screen and (max-width: 768px) {
    .dashboard-body {
        padding: 15px;
        margin-left: 0;
    }

    .tabsContainer {
        width: calc(100vw - 30px);
    }
}


/* detailed tbody */
.indices-tab::-webkit-scrollbar {
    height: 5px
}

.indices-tab::-webkit-scrollbar-thumb {
    background-color: #132A3F;
}

.indices-tab::-webkit-scrollbar-track {
    background-color: #060C23;
}


.detailed-tbody > tr > td {
    font-size: 11px;
    border-bottom: none;
    padding: 9px;
}


.finance-tbody > tr > td {
    padding: 5px 15px;
}

.row-name {
    border: none;
    text-align: left;
}

.detailed-thead {
    border: none;
}

.detailed-thead > tr > th {
    font-size: 10px;
    border: none;
    padding: 12px;
}

.table-bg {
    width: 3000px;
}

.sm-table {
    min-width: 0 !important;
}

.MuiTablePagination-spacer {
    display: none;
}

.bar-lg-text {
    font-size: 14px;
    font-weight: 700;
}

.bar-sm-text {
    font-size: 12px;
    font-weight: 600;
}

.tab-panel {
    max-width: calc(100vw - 120px) !important;
    overflow-x: hidden;
}

@media screen and (max-width: 768px) {
    .tab-panel {
        max-width: calc(100vw - 20px) !important;
    }
}


.selectable-table {
    width: 100%;
    overflow-x: auto;
    margin-top: 20px;
}

.selectable-table-container {
    display: flex;
}

.selectable-table::-webkit-scrollbar {
    height: 5px
}

.selectable-table.dark::-webkit-scrollbar-thumb {
    background-color: #132A3F;
}

.selectable-table.dark::-webkit-scrollbar-track {
    background-color: #060C23;
}

.selectable-table.light::-webkit-scrollbar-thumb {
    background-color: #eaeaea;
}

.selectable-table.light::-webkit-scrollbar-track {
    background-color: #FFFFFF;
}

.financials-table {
    width: 1800px;
}

.financials-table::-webkit-scrollbar {
    height: 5px
}

.financials-table::-webkit-scrollbar-thumb {
    background-color: #132A3F;
}

.financials-table::-webkit-scrollbar-track {
    background-color: #060C23;
}

.package-points {
    font-size: 15px;
    font-weight: 500;
    margin: 5px 0;
    color: #5a6167;
}

.accordion-body {
    padding: 0;
}

.i-sqore-container {
    min-width: 1400px;
    overflow-x: auto;
}

.i-sqore-container > .MuiBox-root > .MuiTabs-root > .MuiTabs-scrollable > .MuiTabs-flexContainer > .MuiButtonBase-root {
    text-transform: none;
    font-size: 14px;
}

.i-sqore-container.dark > .MuiBox-root > .MuiTabs-root > .MuiTabs-scrollable > .MuiTabs-flexContainer > .MuiButtonBase-root > .MuiTab-wrapper {
    color: #ffffff !important;
}

.i-sqore-container.light > .MuiBox-root > .MuiTabs-root > .MuiTabs-scrollable > .MuiTabs-flexContainer > .MuiButtonBase-root > .MuiTab-wrapper {
    color: black !important;
}

.i-sqore-container.dark > .MuiBox-root > .MuiTabs-root > .MuiTabs-scrollable > .MuiTabs-flexContainer > .MuiButtonBase-root > .MuiTab-wrapper {
    color: #ffffff !important;
}

.i-sqore-container.dark > .MuiBox-root > .MuiTabs-root > .MuiButtonBase-root > .MuiSvgIcon-fontSizeSmall {
    color: white;
}

.i-sqore-container.light > .MuiBox-root > .MuiTabs-root > .MuiButtonBase-root > .MuiSvgIcon-fontSizeSmall {
    color: black;
}

.i-sqore-container.light > .MuiBox-root > .MuiTabs-root > .MuiTabs-scrollable > .MuiTabs-flexContainer > .MuiButtonBase-root > .MuiTab-wrapper {
    color: black !important;
}

.company-isqore-container {
    margin: 10px;
    padding: 20px 10px;
    min-width: 220px;
    border: 1px solid #213E44;
    display: flex;
    flex-direction: column;
    border-radius: 30px;
}

.company-text-compare-area {
    margin-top: 10px;
}

.modal-body {
    width: 500px;
    background-color: #F6F7F9;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #F6F7FA;
    position: absolute;
}


.ticker-modal-next-btn {
    font-weight: 600;
    font-size: 14px;
    margin-left: auto;
}

.ticker-modal-select {
    font-size: 12px;
    font-weight: 600;
}


.indices-watchlist-modal-body {
    width: 700px;
    background-color: #F6F7F9;
    padding: 10px;
    border-radius: 5px;
    position: absolute;
}

.hovertext {
    position: relative;
    border-bottom: 1px dotted black;
}

.hovertext:before {
    content: attr(data-hover);
    visibility: hidden;
    opacity: 0;
    width: 200px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    padding: 5px 0;
    transition: opacity 0.3s ease-in-out;

    position: absolute;
    z-index: 1;
    left: 0;
    top: 0%;
}

.etf-category-hover:before {
    left: -100px;
}

.financial-check-hover-text:before {
    left: -400%;
}

@media screen and (max-width: 768px) {
    .financial-check-hover-text:before {
        left: -500%;
    }
}

.hovertext:hover:before {
    opacity: 1;
    visibility: visible;
}

.graph-controls {
    color: black;
    font-size: 12px;
    font-weight: 700;
    height: 35px;
}

.analyst-toolbar {
    margin-left: 17px;
    display: flex;
    margin-bottom: 2px;
}

.toolbar {
    margin-left: 17px;
    display: flex;
    margin-bottom: 5px;
}

.apexcharts-tooltip {
    color: #0C1B29;
}


.generic-modal-body {
    background-color: #0C1B29;
    padding: 10px;
    position: absolute;
    border-radius: 10px;
}

.access-locked-container {
    filter: blur(8px);
    -webkit-filter: blur(8px);
}

.close-modal {
    color: var(--color-text);
    font-size: 12px;
    margin-left: auto;
    cursor: pointer;
}

.generic-lg-table-container {
    overflow-y: hidden;
    /*max-height: 620px;*/
}

.item-right-align-th {
    text-align: right;
    /*padding: 6px 4px !important;*/
}

.item-right-align-td {
    text-align: right;
    padding: 4px 7px !important;
}

.item-center-align-td {
    text-align: center;
    padding: 4px 7px !important;
}

.grade_p {
    font-weight: 700;
    border-radius: 5px;
    min-width: 30px;
}

.grade_p_sm_table {
    margin: auto;
}

.grade_p_isqore_table {
    margin: 0 auto 0 0;
}

.generic-lg-table {
    border: none;
}

.generic-lg-table > :not(:first-child) {
    border: none
}

.generic-lg-table > thead {
    border: none;
}

.generic-lg-table > thead > tr {
    background-color: var(--color-headers);

}

.generic-lg-table > thead > tr > th {
    color: var(--color-text);
    font-size: 12px;
    /*border: none;*/
    padding: 4px;
    cursor: pointer;
    border-bottom: 1px solid #265862;
}

.generic-lg-table > tbody {
    border: none;
}

.generic-lg-table > tbody > tr {
    background-color: var(--color-foreground);
}

.generic-lg-table > tbody > tr > td {
    color: var(--color-text);
    font-size: 12px;
    border: none;
    padding: 4px;
    font-weight: 400;
}

/*.valuation-data-table {*/
/*    & th {*/
/*        padding: 2px;*/
/*    }*/
/*}*/

.generic-tabs-container {
    margin-top: 5px;
}

.child-tabs-container {
    margin-top: 0px;
}

.generic-tabs-container > .MuiBox-root > .MuiTabs-root > .MuiTabs-scrollable > .MuiTabs-flexContainer > .MuiButtonBase-root {
    text-transform: none;
    font-size: 14px;
    background-color: var(--color-foreground) !important;
    color: var(--color-text) !important;
}

.generic-tabs-container > .MuiBox-root > .MuiTabs-root > .MuiTabs-scrollable > .MuiTabs-flexContainer > .Mui-selected {
    background-color: var(--color-headers) !important;
}

.MuiTabScrollButton-root.Mui-disabled {
    opacity: 100% !important;
}

.MuiAppBar-colorPrimary {
    background-color: var(--color-headers) !important;
    color: var(--color-text) !important;
}

.MuiAppBar-root {
    box-shadow: none !important;
}

.navbar-tab-container > .MuiPaper-root > .MuiTabs-root > .MuiTabs-scroller > .MuiTabs-flexContainer > .MuiButtonBase-root > .MuiTab-wrapper {
    text-transform: none;
    font-size: 14px;
    color: var(--color-text) !important;
}

.navbar-tab-container > .MuiPaper-root > .MuiTabs-root > .MuiTabs-scroller > .MuiTabs-flexContainer > .Mui-selected {
    background-color: var(--color-headers) !important;
}

@media screen and (max-width: 567px) {
    .navbar-tab-container > .MuiPaper-root > .MuiTabs-root > .MuiTabs-scroller > .MuiTabs-flexContainer > .MuiButtonBase-root > .MuiTab-wrapper {
        font-size: 11px !important;
    }
}

.generic-loading-container {
    display: flex;
    width: 100%;
    height: 100%;
}

.index-no-component-found {
    color: var(--color-text);
    font-size: 15px;
    font-weight: 700;
}

.see-more-option {
    color: #1BA2DA;
    font-size: 17px;
    font-weight: 700;
    margin: 10px auto;
    cursor: pointer;
}

.show-results-number {
    font-size: 12px;
    font-weight: 400;
    color: var(--color-text);
}

.entire-body-lg {
    position: relative;
    width: 100%;
    height: 100%;
}

.entire-body-sm {
    position: relative;
}

.blur {
    filter: blur(3px);
    -webkit-filter: blur(3px);
}

.blocker-container {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 100;
}

.wall-stopper-container {
    margin: auto;
    /*background-color: var(--color-foreground);*/
    border-radius: 10px;
    padding: 10px;
}

.wall-stopper-container-lg {
    width: 500px;
    height: 300px;
}

.wall-stopper-container-sm {
    width: 320px;
    height: 100px;
}

.wall-stopper-absolute {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    position: absolute;
    z-index: 100;
    display: flex;
}

.wall-stopper-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    font-weight: 700;
    color: var(--color-text);
}

.wall-stopper-content-lg > div > p {
    font-size: 15px;
    margin-bottom: 10px;
}

.wall-stopper-content-sm > div > p {
    font-size: 10px;
    margin-bottom: 5px;
}

.payment-subscribe-btn {
    background-color: transparent;
    background-image: linear-gradient(150deg, #2F78DA 0%, #F2295B 100%);
    color: white;
    font-weight: 600;
    border-radius: 20px;
    cursor: pointer;
}

.cookie-link {
    color: #0C1B29;
    font-weight: 600;
    text-decoration: none;
}

.cookie-link:hover {
    color: #0C1B29;
}

.lang-btn {
    font-size: 12px;
    margin-right: 10px;
}

.selected-lang-btn {
    color: white;
    background-color: transparent;
    background-image: linear-gradient(150deg, #2F78DA 0%, #F2295B 100%);
}

.subscribe-btn {
    color: white;
    font-weight: 600;
    border-radius: 20px;
    cursor: pointer;
}

.payments-subscribe-btn {
    background-color: transparent;
    background-image: linear-gradient(150deg, #2F78DA 0%, #F2295B 100%);
}

.active-subscribe-btn {
    background-color: #282626 !important;
    cursor: not-allowed;
}

.payment-subscribe-btn:hover {
    color: white;
}

.wall-stopper-content-lg > div > .payment-subscribe-btn {
    font-size: 14px;
    padding: 10px 0;
}

.wall-stopper-content-sm > div > .payment-subscribe-btn {
    font-size: 10px;
    padding: 10px 0;
}


@media screen and (max-width: 567px) {
    .wall-stopper-container-lg {
        width: 320px;
        height: 150px;
    }

    .wall-stopper-content-lg > div > p {
        font-size: 12px;
    }

    .wall-stopper-content-lg > div > .payment-subscribe-btn {
        font-size: 12px;
    }
}

.blocker-img {
    width: 100%
}

div:has( > .company-overview-tab-panel) {
    overflow: hidden !important;
}

.isqore-lg-table-td {
    display: flex;
}

.isqore-lg-table-td-div {
    text-align: center;
    display: flex;
    margin: auto 0 auto auto;
}

.isqore-td-separator {
    margin: auto 5px;
}

.MuiTablePagination-caption {
    color: var(--color-text) !important;
}
