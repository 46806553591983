.screener-filter {
    background-color: var(--color-foreground);
    padding: 10px;
}

.screener-filter-meta-text {
    font-size: 18px;
    font-weight: 600;
    color: var(--color-text);
}

@media screen and (max-width: 768px) {
    .screener-grid {
        flex-direction: column-reverse;
    }
}

@media screen and (max-width: 685px) {
    .screener-filter-meta-text {
        font-size: 14px;
    }
}

.estimated-results-container {
    display: flex;
    width: 100%;
    height: 100%;
    padding: 0 10px;
}

.estimated-results-inner-container {
    margin: auto;
}

@media screen and (max-width: 768px) {
    .estimated-results-inner-container {
        margin: 0 auto 0 0;
    }

    .estimated-results-container {
        padding: 0;
    }

    .estimated-results-text {
        font-size: 14px;
    }

    .estimated-results-results {
        font-size: 14px;
    }
}

.estimated-results-text {
    font-size: 18px;
    font-weight: 600;
    color: var(--color-text);
}

.estimated-results-results {
    font-size: 18px;
    font-weight: 600;
    color: var(--color-text);
}

.screener-filter-component {
    max-height: 535px;
    overflow-y: auto;
    margin: 5px 0;
    padding: 5px 0;
}

@media screen and (max-width: 685px) {
    .screener-filter-component {
        max-height: 300px;
    }
}

.filter-component-group {
    margin: 0 0 10px 0;
}

.filter-component-group-container {
    background-color: var(--color-background);
    border-radius: 10px;
    padding: 5px 10px;
}

.filter-component-group-container-close {
    color: var(--color-text);
    margin: auto 0 auto auto;
    cursor: pointer;
    font-weight: 600;
    font-size: 18px;
}

.filter-component {
    margin: 5px;
}

.filter-component-group-title {
    font-size: 16px;
    font-weight: 600;
    margin: auto 0;
    color: var(--color-text);
}

@media screen and (max-width: 685px) {
    .filter-component-group-title {
        font-size: 12px;
    }
}

.filter-component-title {
    font-size: 11px;
    font-weight: 600;
    margin: auto 0;
    color: var(--color-text);
}

.filter-component-region-tab-container {
    display: flex;
}

.filter-component-separator {
    color: var(--color-text);
}

.filter-component-region-tab {
    min-width: 140px;
    margin: 4px 4px 4px 0;
    padding: 4px;
    display: flex;
    background-color: var(--color-foreground);
    border-radius: 5px;
    cursor: pointer;
}

.filter-component-region-tab-text {
    margin: auto 5px auto 0;
    font-size: 12px;
    color: var(--color-text);
}

.filter-component-region-tab-text-add {
    font-weight: 600;
}

.filter-component-region-tab-close {
    margin: auto 0 auto auto;
    font-size: 12px;
    color: var(--color-text);
}

.filter-component-region-tab-add {
    margin: auto 0 auto auto;
    font-size: 12px;
    color: var(--color-text);
    font-weight: 600;
}

.price-compare {
    font-size: 10px;
    width: 140px;
    margin: auto 10px auto 0;
    background-color: var(--color-foreground);
    color: var(--color-text);
    border: 1px solid var(--color-border) !important;
}

.price-input {
    font-size: 10px;
    width: 135px;
    margin: auto 10px auto 0;
}

.price-input-separator {
    color: var(--color-text);
    margin: auto 10px auto 0;
}

.filter-component-price-div {
    display: flex;
}

@media screen and (max-width: 685px) {
    .price-compare {
        width: 135px;
    }

    .price-input {
        width: 135px;
        margin: 10px 10px 10px 0;
    }

    .price-input-separator {
        margin: auto 10px auto 0;
    }

    .filter-component-price-div {
        display: block;
    }
}

.market-cap-item {
    color: var(--color-text);
    border: 1px solid var(--color-border);
    border-radius: 5px;
    font-size: 10px;
    padding: 5px;
    margin: 5px 5px 5px 0;
    cursor: pointer;
}

.market-cap-item-selected {
    color: var(--color-selected-text);
    background-color: var(--color-selected);
    font-weight: 600;
}

.market-cap-item-div {
    margin: 5px 5px 5px 0;
}

.market-cap-input {
    font-size: 10px;
}

.screener-add-filter-btn {
    font-size: 12px;
    font-weight: 700;
    margin: 10px 0;
}

.screener-add-search-btn {
    font-size: 12px;
    font-weight: 700;
    margin: 10px 0 10px 10px;
}

.screener-filter-modal {
    width: 800px;
    background-color: var(--color-foreground);
    padding: 10px;
    border-radius: 5px;
    position: absolute;
}

.new-screener-filter-modal {
    width: 1000px;
    height: 550px;
}

.exchange-filter-modal {
    width: 1000px;
}

.screener-filter-modal-inner-container {
    max-height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
}

.screener-filter-component-div {
    display: flex;
}

.filter-component-modal-title {
    font-size: 12px;
    font-weight: 600;
    color: var(--color-text);
}

.filter-component-modal-name {
    font-size: 12px;
    font-weight: 400;
    margin: 0 0 0 5px;
    color: var(--color-text);
}

.screener-modal-add-filter-text {
    font-size: 16px;
    font-weight: 600;
    color: var(--color-text);
}

@media screen and (max-width: 768px) {
    .screener-filter-modal {
        width: 500px;
    }

    .screener-filter-modal-inner-container {
        max-height: 400px;
        overflow-y: auto;
        overflow-x: hidden;
    }
}

@media screen and (max-width: 567px) {
    .screener-filter-modal {
        width: 350px;
        max-height: 500px;
    }
}

.screener-filter-container-headings {
    color: var(--color-text);
    font-weight: 600;
    margin-bottom: 10px;
}

.screener-filter-category-items {
    padding: 10px;
    font-size: 12px;
    background-color: var(--color-foreground);
    color: var(--color-text);
    cursor: pointer;
    display: flex;
}

.screener-filter-category-items:hover {
    background-color: var(--color-background);
}

.screener-filter-category-items-locked {
    background-color: var(--color-disabled-background);
}

.screener-filter-category-items-locked:hover {
    background-color: var(--color-disabled-background);
}

.screener-filter-model-heading {
    font-size: 16px;
    font-weight: 700;
    color: var(--color-text);
    margin-bottom: 5px;
}

.screener-filter-filters-container {
    width: 100%;
    max-height: 400px;
    overflow-y: auto;
}

.screener-filter-search {
    font-size: 12px;
    color: var(--color-text);
    background-color: var(--color-background);
    border: 1px solid var(--color-border);
}

.screener-filter-category-items-selected {
    background-color: var(--color-background);
}


.screener-filter-select-div {
    border: 1px solid var(--color-border);
    color: var(--color-text);
    background-color: var(--color-background);
    font-size: 12px;
    font-weight: 500;
    padding: 2px 8px;
    border-radius: 5px;
    margin: 2px;
    cursor: pointer;
}

.screener-filter-select-div:hover {
    color: var(--color-background);
    background-color: var(--color-text);
}

.screener-filter-select-div-selected {
    color: var(--color-background);
    background-color: var(--color-text);
}

.screener-exchange-div {
    background-color: var(--color-background);
    display: flex;
    padding: 4px 6px;
    border-radius: 5px;
    cursor: pointer;
}
