.profile-container {
    width: 100%;
    background-color: var(--color-foreground);
    padding: 20px;
    border-radius: 15px;
}

.text-input-field-container {
    margin: 20px 0;
    display: flex;
}

.profile-header {
    font-size: 17px;
    font-weight: 700;
    margin: auto 0;
    color: var(--color-text);
}

@media screen and (max-width: 768px) {
    .profile-header {
        font-size: 15px;
    }
}

.text-input-field-label {
    font-weight: 600;
    cursor: pointer;
    font-size: 14px;
    color: #1BA1DA;
    width: 150px;
}

@media screen and (max-width: 768px) {
    .text-input-field-label {
        font-size: 12px;
    }
}

.text-input-field-app-settings {
    font-weight: 600;
    cursor: pointer;
    font-size: 14px;
    color: #1BA1DA;
    width: 200px;
    margin: auto 0;
}

@media screen and (max-width: 768px) {
    .text-input-field-app-settings {
        font-size: 12px;
    }
}

.subscription-fields-container {
    display: flex;
    margin: 20px 0;
}

.btn-request-change-password {
    font-size: 14px;
    font-weight: 600;
    height: 35px;
    color: #FFFFFF;
    cursor: pointer;
    margin: auto 0 auto auto;
}

.btn-request-change-password-lg {
    display: block;
}

.btn-request-change-password-sm {
    display: none;
}

@media screen and (max-width: 768px) {
    .btn-request-change-password {
        font-size: 12px;
    }

    .btn-request-change-password-lg {
        display: none;
    }

    .btn-request-change-password-sm {
        display: block;
    }
}

.app-settings-fields-container {
    display: flex;
    margin: 20px 0;
}

.subscription-label {
    font-weight: 600;
    cursor: pointer;
    font-size: 14px;
    color: #1BA1DA;
    width: 200px;
}

@media screen and (max-width: 768px) {
    .subscription-label {
        font-size: 12px;
    }
}

.text-input-field-value {
    font-weight: 600;
    font-size: 14px;
    color: var(--color-text);
    margin: auto 0;
}

.subscription-value {
    font-weight: 600;
    cursor: pointer;
    font-size: 14px;
    color: var(--color-text);
    margin-left: 10px;
}

@media screen and (max-width: 768px) {
    .text-input-field-value {
        font-size: 12px;
    }

    .subscription-value {
        font-size: 12px;
        text-align: right;
    }
}

.text-input-field-input {
    font-size: 14px;
    border-color: var(--color-border);
    background-color: var(--color-background);
    color: var(--color-text);
    font-weight: 600;
    width: 100%;
}

.text-input-field-input:focus {
    background-color: var(--color-background);
    color: var(--color-text);
    box-shadow: none;
}

@media screen and (max-width: 768px) {
    .text-input-field-input {
        font-size: 12px;
    }
}

.profile-pencil {
    color: var(--color-text);
    margin: auto 10px;
    cursor: pointer;
    font-size: 14px;
}

.user-info-avatar {
    width: 200px;
    border-radius: 50%;
    height: 200px;
    border: 5px solid #1BA1DA;
    background-color: #03111E;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.profile-size-warning {
    margin-top: 10px;
    color: var(--color-text);
    font-size: 12px;
    text-align: center;
}

@media screen and (max-width: 768px) {
    .user-info-avatar {
        width: 170px;
        height: 170px;
    }
}

.profile-save-btn {
    background-color: #1BA1DA;
    color: white;
    font-size: 12px;
    font-weight: 600;
    width: 100px;
    height: 35px;
    border-radius: 5px;
    cursor: pointer;
    margin: 0 10px;
}

.cancel-subscription-btn {
    margin-left: auto;
    color: white;
    font-size: 14px;
    font-weight: 600;
}

.profile-camera {
    right: 15px;
    top: 10px;
    position: absolute;
    font-size: 30px;
    color: #1BA1DA;
}

.cancel-subscription-modal {
    width: 450px;
    background-color: var(--color-foreground);
    padding: 10px;
    border-radius: 5px;
    position: absolute;
}

.cancel-subscription-modal-heading-container {
    display: flex;
}

.cancel-subscription-modal-heading {
    color: var(--color-text);
    font-size: 14px;
    font-weight: 600;
    margin: auto 0;
}

.cancel-subscription-modal-close {
    color: var(--color-text);
    font-size: 14px;
    margin: auto 0 auto auto;
}

.cancel-subscription-modal-hr {
    color: var(--color-text);
}

.cancel-subscription-modal-text {
    color: var(--color-text);
    font-size: 14px;
}

.cancel-subscription-modal-footer-container {
    display: flex;
    margin: 15px 0 5px 0;
}

.btn-close-payment-modal {
    margin: auto 15px auto auto;
    font-size: 14px;
}

.btn-continue-payment-modal {
    margin: auto 0;
    font-size: 14px;
    background-color: #056656;
    color: white;
}

.btn-continue-payment-modal:hover {
    color: white;
}
