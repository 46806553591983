.exchange-search-filter {
    color: var(--color-text);
    border: 1px solid var(--color-border);
    background-color: var(--color-background);
    border-radius: 5px;
    padding: 5px;
    font-size: 12px;
    margin-bottom: 10px;
}

.exchange-search-filter:focus {
    color: var(--color-text);
    background-color: var(--color-background);
    box-shadow: none;
}

.best-iSqore-filter-modal-body {
    width: 500px;
    /*height: 350px;*/
    background-color: var(--color-foreground);
    padding: 10px;
    border-radius: 5px;
    position: absolute;
}

.fair-value-modal-body {
    width: 370px;
}

.isqore-exchange-modal-title {
    font-size: 17px;
    font-weight: 700;
    color: var(--color-text);
    margin-bottom: 10px;
}

.exchange-list {
    max-height: 300px;
    overflow-y: auto;
    padding: 0 5px;
    margin: 5px 0;
}

.exchange-list::-webkit-scrollbar {
    width: 5px
}

.exchange-list::-webkit-scrollbar-thumb {
    background-color: var(--color-scroll);
}

.exchange-list::-webkit-scrollbar-track {
    background-color: var(--color-background);
}

@media screen and (max-width: 567px) {
    .best-iSqore-filter-modal-body {
        width: 370px;
        padding: 10px 10px 0 10px;
    }

    .exchange-list {
        max-height: 350px;
    }
}

.exchange-searched-container {
    display: flex;
    background-color: var(--color-background);
    border-radius: 5px;
    padding: 10px;
    height: 55px;
    margin: 0 0 10px 0;
    cursor: pointer;
}

.filter-exchange-checkbox {
    margin: auto 10px auto 0;
}

.exchange-searched-name {
    margin: auto 0;
    color: var(--color-text);
    font-size: 12px;
    font-weight: 600;
    text-align: right;
}

.exchange-searched-code {
    margin: auto 5px;
    color: var(--color-text);
    font-size: 12px;
    font-weight: 600;
    text-align: right;
}

.exchange-searched-country {
    margin: auto 0 auto auto;
    color: var(--color-text);
    font-size: 10px;
    font-weight: 400;
    text-align: right;
}

@media screen and (max-width: 567px) {
    .exchange-searched-name {
        font-size: 10px;
    }

    .exchange-searched-code {
        font-size: 10px;
        font-weight: 400;
    }
}

.compare-isqore-container {
    display: flex;
    overflow-x: auto;
}

.isqore-sidebar-text {
    height: 30px;
    padding: 5px;
    font-size: 12px;
    display: flex;
    color: var(--color-text);
}

.isqore-sidebar-border {
    border: 1px solid var(--color-background);
}

.isqore-sidebar-value > p:first-child {
    margin-right: auto;
}

.iSqore-fields-container {
    min-width: 200px;
    margin: 35px 20px 0 0;
    display: flex;
    flex-direction: column;

    .accordion-button {
        height: 30px;
    }
}

.iSqore-fields-container-margin {
    margin: 123px 0 0 0;
}

.accordion-item {
    border: none;
    background-color: transparent;
}

.accordion-button {
    color: var(--color-text);
    background-color: var(--color-headers);
    padding: 4.5px 4px;
}

.accordion-button:focus {
    color: var(--color-text);
    background-color: var(--color-headers);
    box-shadow: none;
}

.accordion-button:not(.collapsed) {
    color: var(--color-text) !important;
    background-color: var(--color-headers);
}

.accordion-button::after {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23265862'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}

.checked-filter-btn {
    background-color: transparent;
    background-image: linear-gradient(150deg, #2F78DA 0%, #F2295B 100%);
    color: white;
    font-size: 12px;
    font-weight: 600;
    margin-left: auto;
}

.checked-filter-btn:hover {
    color: white;
}

.clear-all-btn {
    font-size: 12px;
    font-weight: 600;
    margin-left: auto;
}

.isqore-filter-btn {
    border-radius: 5px;
    cursor: pointer;
    color: var(--color-text);
    border: 1px solid var(--color-border);
    height: 35px;
    width: 90px;
    display: flex;
    margin-bottom: 10px;
}

.isqore-filter-btn > p {
    font-size: 14px;
    margin: auto;
}

.isqore-table {
    table-layout: fixed;
    width: 100%;

    & th {
        font-size: 12px !important;
        font-weight: 600;
        width: 60px;
        vertical-align: middle;
    }

    .isqore-table-title {
        font-size: 20px !important;
        width: 150px;
    }

    .isqore-table-dropdown {
        width: 120px;

        & select {
            font-size: 12px !important;
            background-color: var(--color-foreground);
            color: var(--color-text);
            border: 1px solid var(--color-border) !important;
        }
    }
}

.company-company-logo {
    width: 50px;
    height: 50px;
    margin: 10px auto 0 auto;
    border-radius: 5px;
}
