.login-logo {
    width: 150px;
}

.accounts-container {
    position: relative;
    overflow: hidden;
    z-index: 1;
    background-color: #03111E;
}

.socials {
    margin-right: 20px;
    font-size: 20px;
    color: #1BA2DA;
}

@media screen and (max-width: 567px) {
    .socials {
        margin-right: 15px;
        font-size: 15px;
    }
}

.register-container {
    width: 450px;
    margin: auto;
    border-radius: 20px;
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.01);
    box-shadow: inset 0px 98px 100px -48px rgba(16, 114, 164, 0.1), inset 0px 4px 18px rgba(10, 158, 248, 0.3);
}

.check-inbox-container {
    text-align: center;
    color: white;
    width: 950px;
    margin: auto;
    border-radius: 20px;
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.01);
    box-shadow: inset 0px 98px 100px -48px rgba(16, 114, 164, 0.1), inset 0px 4px 18px rgba(10, 158, 248, 0.3);
}

@media screen and (max-width: 992px) {
    .check-inbox-container {
        width: 100%;
    }
}

.check-inbox-container-heading {
    font-weight: 600;
    font-size: 32px;
}

.check-inbox-thankyou-heading {
    font-weight: 700;
    font-size: 42px;
}

.check-inbox-logo {
    width: 150px;
    display: block;
    margin: auto;
}

.check-inbox-container-text {
    font-weight: 400;
    font-size: 14px;
}

.change-password-container {
    width: 450px;
    margin: auto;
    border-radius: 20px;
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.01);
    box-shadow: inset 0px 98px 100px -48px rgba(16, 114, 164, 0.1), inset 0px 4px 18px rgba(10, 158, 248, 0.3);
}

.form-control:disabled {
    /*background-color: #101925;*/
    cursor: not-allowed;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
}

.login-container {
    max-width: 450px;
    height: 100%;
    margin: auto;
    border-radius: 20px;
    padding: 30px;
    background-color: #0C1B29;
    box-shadow: inset 0px 98px 100px -48px rgba(16, 114, 164, 0.1), inset 0px 4px 18px rgba(10, 158, 248, 0.3);
}

.login-or-text {
    font-size: 14px;
    font-weight: 700;
    color: #FFFFFF;
}

.input-field {
    background-color: #101823;
    color: white;
    border: none;
    font-size: 14px;
    font-weight: 400;
    padding: 12px;
}


.input-field:focus {
    background-color: #101823 !important;
    color: white !important;
}

@media screen and (max-width: 567px) {
    .login-container {
        padding: 25px;
    }

    .input-field {
        font-size: 12px;
    }
}

.form-check-label {
    color: white;
    font-size: 12px;
}

.checkbox-link {
    color: white;
    text-decoration: none;
    font-weight: 600;
}

.checkbox-link:hover {
    color: white;
}

.change-password-email-field {
    background-color: #111C28 !important;
}

.login-btn {
    background-color: #136281;
    color: white;
    font-size: 16px;
    width: 150px;
}

.login-btn:hover {
    color: white;
}

.register-btn {
    background-color: #136281;
    color: white;
    font-size: 16px;
}

.register-btn:hover {
    color: white;
}

.login-whole-container {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    position: relative;
    z-index: 3;
}

.ornament-1 {
    position: absolute;
    width: 288.86px;
    height: 288.86px;
    left: -138.86px;
    top: 639.5px;

    /* Primary */

    background: #1BA2DA;
    opacity: 0.5;
    filter: blur(305px);
}

.ornament-2 {
    z-index: 2;
    position: absolute;
    width: 685.26px;
    height: 685.26px;
    left: 70%;
    top: -40%;
    background: #1BA2DA;
    opacity: 0.5;
    filter: blur(407px);
}

.register-heading-text {
    color: #1BA2DA;
    font-size: 15px;
}

.register-heading-main {
    font-size: 32px;
    font-weight: 700;
    color: #FFFFFF;
}

.register-heading-para {
    color: #969696;
    font-size: 17px;
    line-height: 34px;
}

@media screen and (max-width: 1200px) {
    .register-heading-para {
        color: #969696;
        font-size: 17px;
    }
}

@media screen and (max-width: 992px) {
    .register-heading-para {
        color: #969696;
        font-size: 14px;
    }
}

.accounts-message {
    font-size: 32px;
    font-weight: 700;
    color: white;
}

@media screen and (max-width: 768px) {
    .accounts-message {
        font-size: 18px;
        font-weight: 700;
    }
}

.reg-control-btn {
    color: white;
    font-size: 12px;
    font-weight: 700;
}

.reg-back-btn {
    background-color: #7A1818;
}

.reg-next-btn {
    margin-left: auto;
    background-color: #1BA2DA;
}

@media screen and (max-width: 768px) {
    .register-container {
        height: 100%;
    }

    .register-heading-main {
        font-size: 20px;
        font-weight: 700;
        text-align: center;
    }
}

.input-field-helper-text {
    font-size: 12px;
    font-weight: 600;
    color: white;
    margin-bottom: 2px;
}


input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
    background: url(/src/assets/icons8-calendar-48.png);
}


.delete-image-btn {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    z-index: 999;
    color: white;
    font-size: 14px;
}

.reg-img-container {
    border: 1px solid #1BA1DA;
    display: flex;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 400px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.show-password {
    color: #1BA1DA;
    font-size: 12px;
    font-weight: 700;
    margin: 0 0 0 auto;
    cursor: pointer;
}

.change-password-modal {
    width: 360px;
}

.have-an-account-text {
    font-size: 14px;
    color: #1BA1DA;
    font-weight: 700;
    margin: 25px auto 0 auto;
    cursor: pointer;
}

.captcha {
    margin: 0 auto;
    width: 65%;
    margin-bottom: 1rem;
}

@media screen and (max-width: 480px) {
    .captcha {
        width: 70%;
    }
}

@media screen and (max-width: 425px) {
    .captcha {
        width: 80%;
    }
}

@media screen and (max-width: 375px) {
    .captcha {
        width: 93%;
    }
}

@media screen and (max-width: 375px) {
    .captcha {
        width: 100%;
        float: left;
    }
}
