.etf-profiler-description-container {
    padding: 5px;
}

.etf-profiler-description-inner-container {
    padding: 10px;
    background-color: var(--color-foreground);
    width: 100%;
    border-radius: 10px;
}

.etf-profiler-description-inner-container-heading {
    font-weight: 700;
    font-size: 14px;
    margin-bottom: 5px;
    color: var(--color-text);
}

.etf-profiler-description-inner-container-text {
    font-size: 12px;
    color: var(--color-text);
}

.etf-profiler-top-bar-container {
    padding: 5px;
}

.etf-profiler-top-bar-inner-container {
    background-color: var(--color-foreground);
    padding: 20px;
    border-radius: 10px;
    display: flex;
}

.etf-profiler-name {
    font-size: 14px;
    font-weight: 700;
    color: var(--color-text);
}

.etf-profiler-category {
    font-size: 12px;
    color: var(--color-text);
}

.etf-profiler-adjusted-close {
    font-size: 14px;
    font-weight: 700;
    color: var(--color-text);
}

.etf-profiler-adjusted-close-percentage-1 {
    color: #4ebe96;
    font-size: 12px;
    margin-left: 10px;
}

.etf-profiler-adjusted-close-percentage-2 {
    color: #4ebe96;
    font-size: 12px;
}

.etf-iSqore-text {
    color: var(--color-text);
    font-size: 11px;
    font-weight: 600;
    text-align: center;
}

.etf-sector-weight {
    display: flex;
    background-color: var(--color-foreground);
    padding: 5px;
    border-radius: 0 0 10px 10px;
    width: 100%;
}

.etf-sector-weight-sector {
    display: flex;
    padding: 5px 0;
}

.etf-sector-weight-color {
    padding: 10px;
    border-radius: 50%;
    width: 2px;
    height: 2px;
}

.etf-sector-weight-title {
    font-size: 9px;
    font-weight: 600;
    margin-left: 5px;
    color: var(--color-text);
}

.etf-profiler-chart-container {
    height: 100%;
    width: 100%;
}

.company-overview-pie-chart-container {
    /*height: 100%;*/
    /*width: 100%;*/
}

.etf-profiler-line-chart-container {
    height: 100%;
    width: 100%;
    padding: 0 5px;
}

.etf-profiler-inner-line-chart-container {
    width: 100%;
    background-color: var(--color-foreground);
    padding: 10px;
    border-radius: 0 0 10px 10px;
    height: 345px;
}

.etf-profiler-pie-chart-container {
    width: 100%;
    background-color: var(--color-foreground);
    padding: 0 10px 10px 10px;
    border-radius: 0 0 10px 10px;
    height: 296px;
}

.company-overview-pie-chart-inner-container {
    width: 100%;
    padding: 0 10px;
    border-radius: 0 0 10px 10px;
    height: 320px;
}

.company-overview-pie-chart-title {
    margin: 0 0 10px 10px;
    font-size: 18px;
    font-weight: 600;
    color: var(--color-text);
}

@media screen and (max-width: 1200px) {
    .revenue-by-segments-container {
        flex-direction: column-reverse;
    }

    .company-overview-pie-chart-container {
        margin-bottom: 40px;
    }

    .company-overview-pie-chart-inner-container {
        height: 280px;
        margin: auto;
    }

    .company-overview-pie-chart-title {
        font-size: 17px;
        margin-bottom: 10px;
        margin-left: 0;
    }
}

@media screen and (max-width: 490px) {
    .company-overview-pie-chart-title {
        text-align: center;
    }
}

.etf-sm-table-body > tbody > tr > td {
    padding: 5px !important;
}

.etf-profiler-stat-table-container {
    padding: 0 5px;
    height: 100%;
}

.performance-table > tbody > tr > td {
    padding: 3.78px !important;
}

@media screen and (max-width: 567px) {
    .etf-profiler-stat-table-container {
        margin: 10px 0;
    }
}

.market-overview-chart-container {
    margin-bottom: 10px;
    padding: 0 5px;
}

.market-mover-chart-container {
    margin-bottom: 0;
}

.market-overview-inner-container {
    width: 100%;
    background-color: var(--color-foreground);
    border-radius: 10px;
    padding: 10px 10px 0 0;
    height: 333px;
}

.market-mover-inner-container {
    padding: 10px 10px 10px 0;
    height: 260px;
}

.market-overview-vix-outer-container {
    padding: 0;
}

.market-mover-vix-container {
    height: 262px;
    padding: 10px 0;
}

.fear-greed-gauge-container {
    margin: 0 auto;
    width: 100%;
    height: 90px;
}

@media screen and (max-width: 1200px) {
    .fear-greed-gauge-container {
        width: 400px;
        height: 180px;
    }
}

@media screen and (max-width: 496px) {
    .fear-greed-gauge-container {
        width: 200px;
        height: 100px;
    }
}

.radial-chart-container {
    padding: 0 5px;
    margin-bottom: 10px;
    cursor: pointer;
}

.etf-profiler-lg-table {
    table-layout: fixed;
    width: 100%;
}

.etf-profiler-lg-table > thead > tr > th {
    width: 150px !important;
}

.etf-profiler-lg-table > tbody > tr > td {
    cursor: pointer !important;
}


.etf-profiler-lg-table > thead > tr > .etf-profiler-code {
    width: 125px !important
}

.etf-profiler-lg-table > thead > tr > .etf-profiler-company-name {
    width: 200px !important
}

.etf-profiler-lg-table > thead > tr > .etf-profiler-exchange {
    width: 100px !important;
}

.etf-profiler-lg-table > thead > tr > .etf-profiler-ISIN {
    width: 100px !important
}

.etf-profiler-lg-table > thead > tr > .etf-profiler-industry {
    width: 180px !important
}

.etf-profiler-lg-table > thead > tr > .etf-profiler-isqore {
    width: 100px !important;
}

.etf-profiler-lg-table > thead > tr > .etf-profiler-lastprice {
    width: 100px !important
}

.etf-profiler-lg-table > thead > tr > .etf-profiler-weight {
    width: 100px !important
}

.etf-profiler-lg-table > thead > tr > .etf-profiler-change {
    width: 100px !important
}

.etf-profiler-lg-table > thead > tr > .etf-profiler-table-star {
    width: 40px !important
}

.etf-lg-table {
    table-layout: fixed;
    width: 100%;
}

.etf-lg-table > thead > tr > th {
    width: 100px !important;
}

.etf-lg-table > tbody > tr > td {
    cursor: pointer !important;
}

.etf-lg-table > thead > tr > .etf-name {
    width: 200px !important
}

.etf-lg-table > thead > tr > .etf-code {
    width: 60px !important
}

.etf-lg-table > thead > tr > .etf-exchange {
    width: 80px !important
}

.etf-lg-table > thead > tr > .etf-change {
    width: 80px !important
}

.etf-lg-table > thead > tr > .etf-price {
    width: 80px !important
}

.etf-lg-table > thead > tr > .etf-ytd {
    width: 60px !important
}

.etf-lg-table > thead > tr > .etf-week {
    width: 60px !important
}

.etf-top-bar-info {
    color: var(--color-text);
    font-size: 13px;
}
