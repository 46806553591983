
.generic-table-body {
    border: none;
}

.generic-table-body > tbody > tr {
    color: var(--color-text);
    border-bottom: none;
    font-size: 12px;
    border-bottom: 0.5px solid #213E44;
}

.generic-table-body > tbody > tr > td {
    font-weight: 400;
    border-bottom: none;
    font-size: 12px;
    padding: 5px 0;
}

.fear-and-greed-container {
    padding: 10px;
    border-radius: 10px;
    background-color: var(--color-foreground);
    text-align: center;
    display: flex;
    flex-direction: column;

    & h4 {
        color: var(--color-text);
        font-size: 15px !important;
        font-weight: 600;
        margin: 0 auto 10px auto;
    }

    .gauge-text {
        font-size: 14px;

        & h5 {
            margin: 0;
            padding: 0;
            font-weight: 600;
        }

        & p {
            margin: 0;
            padding: 0;
        }
    }
}

.market-movers-performance-table {
    table-layout: fixed;
    height: 94%;
    width: 100%;
    margin-bottom: 0;

    & tr {
        border: none !important;
    }

    & td {
        text-align: center;
    }

    .tb-heading {
        font-size: 15px;
        font-weight: 600;
        vertical-align: middle;
    }

    .tb-value {
        padding: 3px;

        .tb-value-container {
            padding: 10px;
            border-radius: 10px;
            font-size: 13px;
            font-weight: 700;
            color: white;
            height: 100%;
            width: 100%;
            text-align: center;
            align-content: center;

            & p {
                margin: auto;
            }
        }
    }
}

.market-overview-summary-table-container {
    margin-bottom: 10px;
    padding: 0 5px;
}

.market-overview-summary-table-body-container {
    background-color: var(--color-foreground);
    padding: 10px;
}

.market-overview-summary-table-title {
    background-color: var(--color-headers);
    padding: 15px;
    border-radius: 10px 10px 0 0;
    width: 100%;
    display: flex;

    & p {
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 1px;
        color: var(--color-text);
        cursor: pointer;
        margin: auto 0;
    }

    .selected {
        color: var(--color-selected-text);
    }

    .selected > p {
        color: var(--color-selected-text);
    }

    .unselected {
        color: var(--color-unselected-text);
    }

    .unselected > p {
        color: var(--color-unselected-text);
    }
}

.key-facts-table-title {
    padding: 11.5px 15px;
}

.market-overview-summary-table-heading {
    font-weight: 700;
}

.news-container {
    max-height: 262px;
    overflow-y: auto;
}

.company-news-container {
    max-height: 274px;
    overflow-y: auto !important;
}

.isqore-keyfacts-inner-container {
    max-height: 260px;
    overflow-y: auto !important;
}

.company-keyfacts-container {
    display: flex;
    flex-wrap: wrap;
}

.news-inner-container {
    display: flex;
    margin-bottom: 15px;
    text-decoration: none;
}

.news-inner-container > img {
    width: 65px;
    height: 55px;
    border-radius: 10px;
}

.news-inner-container > div {
    margin-left: 10px;
}

.news-inner-container > div > .title {
    font-size: 12px;
    color: var(--color-text);
    font-weight: 600;
}

.news-inner-container > div > .publishedDate {
    font-size: 10px;
    color: #4ebe96;
}

.news-inner-container > div > .read-more {
    font-size: 10px;
    color: #7F8790;
}

/*chart*/
.chart-container {
    padding: 0 5px;
    margin-bottom: 10px;
}

.market-overview-chart-name {
    font-size: 15px;
    font-weight: 700;
    color: var(--color-text);
    margin: auto auto auto 0;
}

/* tab container */

.market-overview-tabs-container > .MuiBox-root > .MuiTabs-root > .MuiTabs-scrollable > .MuiTabs-flexContainer > .MuiButtonBase-root {
    text-transform: none;
    font-size: 14px;
}

.market-overview-tabs-container > .MuiBox-root > .MuiTabs-root > .MuiTabs-scrollable > .MuiTabs-flexContainer > .MuiButtonBase-root > .MuiTab-wrapper {
    color: var(--color-text) !important;
}

.market-overview-tabs-container > .MuiBox-root > .MuiTabs-root > .MuiButtonBase-root > .MuiSvgIcon-fontSizeSmall {
    color: var(--color-text) !important;
}

.MuiSvgIcon-root {
    color: #ffffff;
}

.generic-tab-panel {
    background-color: var(--color-background) !important;
    padding: 5px 0 0 0;
}

.MuiTabs-scroller {
    border-radius: 10px;
}

.MuiTabs-root {
    min-height: 38px !important;
}

.MuiTab-root {
    min-height: 38px !important;
}

.market-overview-indices-table {
    table-layout: fixed;
    width: 100%;
}

.market-overview-indices-table > thead > tr > th {
    width: 100px !important;
    /*word-wrap: break-word;*/
}

.market-overview-indices-table > thead > tr > .market-overview-table-index-num {
    width: 30px !important
}

.market-overview-indices-table > thead > tr > .market-overview-table-index-name {
    width: 200px !important
}

.market-overview-indices-table > thead > tr > .market-overview-table-index-exchange {
    width: 120px !important
}

.market-overview-indices-table > thead > tr > .market-overview-table-index-average {
    width: 120px !important
}

.market-overview-sector-table {
    table-layout: fixed;
    width: 100%;
}

.market-overview-sector-table > thead > tr > th {
    width: 100px !important;
    /*word-wrap: break-word;*/
}

.market-overview-sector-table > thead > tr > .market-overview-sector-th {
    width: 200px !important;
}

.test-body {
    height: 30px !important;
    overflow-y: scroll !important;
}
