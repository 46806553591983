.fair-value-table-container {
    padding: 5px;
}

.sm-table-name-container {
    color: var(--color-text);
    background-color: var(--color-headers);
    padding: 15px;
    border-radius: 10px 10px 0 0;
    width: 100%;
}

.sm-table-name {
    font-weight: 600;
    font-size: 12px;
}

.sm-table-body-container {
    padding: 0 10px 10px 10px;
    border-radius: 0 0 10px 10px;
    width: 100%;
    background-color: var(--color-foreground);
}

.sm-body-table-row {
    display: flex;
    border-bottom: 1px solid #213E44;
    height: 45px;
}

.sm-body-table-row-bold-bottom {
    border-bottom: 2px solid #213E44;
}

.sm-body-table-row-key {
    font-size: 12px !important;
    font-weight: 600 !important;
    /*color: var(--color-text);*/
    /*margin: auto auto auto 0;*/
}

.sm-body-table-row-key-bold {
    font-weight: 600;
}

.sm-body-table-row-value {
    color: var(--color-text);
    font-size: 12px;
    margin: auto 0;
}

.sm-body-editable-field {
    font-size: 12px;
    width: 100%;
    margin: auto;
    padding: 4.5px 10px;
    color: var(--color-text);
    background-color: var(--color-headers);
}

.sm-body-editable-field:focus {
    font-weight: 600;
    color: var(--color-text);
    background-color: var(--color-headers);
}

.valuation-def-container {
    padding: 10px;
    background-color: var(--color-foreground);
    width: 100%;
    border-radius: 10px;
    margin: 10px 0;
}

.valuation-def-container-heading {
    font-size: 12px;
    color: var(--color-text);
    font-weight: 700;
}

.valuation-def-container-seperator {
    height: 1px;
    width: 100%;
    background-color: var(--color-border);
    margin: 5px 0;
}

.valuation-def-container-text {
    color: var(--color-text);
    font-size: 12px;
    font-weight: 400;
}

.valuation-table-container {
    margin-bottom: 10px;
    padding: 0 5px;
}

.valuation-table-title {
    background-color: var(--color-headers);
    padding: 15px;
    border-radius: 10px 10px 0 0;
    width: 100%;
}

.valuation-table-title > p {
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
    color: var(--color-text);
}

.valuation-table-body-container {
    background-color: var(--color-foreground);
    padding: 10px;
}

.valuation-sm-table > tbody > tr > td {
    font-size: 12px !important;
}

.detailed-analysis-container-redirect {
    border-radius: 0 0 10px 10px;
    background-color: var(--color-headers);
}

.detailed-analysis-inner-container-redirect {
    text-align: center;
    width: 100%;
    padding: 10px;
}

.detailed-analysis-inner-container-redirect > p {
    color: var(--color-text);
    font-size: 12px;
    font-weight: 600;
    cursor: pointer;
}

.percentage-sign-valuation {
    color: var(--color-text);
    font-size: 12px;
    margin: auto 0 auto 10px;
}

.description-valuation > p {
    color: var(--color-text);
    font-size: 14px;
    margin-bottom: 12px;
}

.valuation-accordion-header {
    font-weight: 600;
    font-size: 14px;
    padding: 5px;
}

.valuation-accordion-body {
    font-size: 14px;
    padding: 10px;
    color: var(--color-text);
    background-color: var(--color-foreground);
}

.valuation-accordion-body > p {
    margin-bottom: 10px;
}

.valuation-data-table > thead > tr > th {
    padding: 10px !important;
    font-size: 12px;
    font-weight: 600;
}
