
.sidebar::-webkit-scrollbar {
    width: 5px;
}

.sidebar::-webkit-scrollbar-thumb {
    background-color: var(--color-foreground);
}

.sidebar::-webkit-scrollbar-track {
    background-color: var(--color-background)
}


.sidebar {
    position: absolute;
    z-index: 1000;
    background-color: var(--color-foreground);
    height: calc(100vh - 65px);
    overflow-y: auto !important;

}

.icon-hover {
    width: 22px;
    display: none;
    margin-right: 10px;
}

.sidebar-open {
    width: 250px;
    transition: 0.3s ease-in-out;
    overflow-x: hidden;
}

.sidebar-open > a > .sidebar-list-item {
    width: 250px;
}

.sidebar-open > a > .sidebar-list-item-selected {
    width: 250px;
    color: #1BA2DA;
    background-color: var(--color-background);
    transform: translateX(10px);
    border-radius: 10px;
}

.sidebar-open > a > .sidebar-list-item-selected > .sidebar-icon {
    width: 22px;
    margin-right: 10px;
}

.sidebar-open > a > .sidebar-list-item-selected > .sidebar-text {
    display: block;
}


.sidebar-open > a > .sidebar-list-item:hover {
    color: #1BA2DA;
    background-color: var(--color-background);
    transition: 0.3s ease-in-out;
    transform: translateX(10px);
    border-radius: 10px;
}

.sidebar-open > a > .sidebar-list-item > .sidebar-text {
    display: block;
}

.sidebar-open > a > .sidebar-list-item:hover > .sidebar-text {
    color: #1BA2DA;
}

.sidebar-open > a > .sidebar-list-item:hover > .icon {
    display: none;
}


.sidebar-open > a > .sidebar-list-item:hover > .icon-hover {
    display: block;
}


.sidebar-closed {
    width: 70px;
    transition: 0.3s ease-in-out;
    overflow-x: auto
}

.sidebar-closed > a > .sidebar-list-item:hover {
    background-color: var(--color-background);
    border-radius: 10px;
}

.sidebar-closed > a > .sidebar-list-item-selected {
    background-color: var(--color-background);
    border-radius: 10px;
}

.sidebar-closed > a > .sidebar-list-item:hover > .icon {
    display: none;
}

.sidebar-closed > a > .sidebar-list-item-selected > .sidebar-icon {
    width: 22px;
}

.sidebar-closed > a > .sidebar-list-item:hover > .icon-hover {
    display: block;
    width: 20px;
}

@media screen and (max-width: 768px) {
    .sidebar-closed {
        width: 0;
    }
}

.sidebar-list-item-selected {
    display: flex;
    margin: 15px;
    width: 42px;
    padding: 10px;
}

.sidebar-list-item {
    display: flex;
    margin: 20px 15px;
    width: 42px;
    padding: 10px;
    cursor: pointer;
}

.icon {
    width: 22px;
    height: 22px;
    margin-right: 10px;
}

.sidebar-text {
    color: var(--color-text);
    margin: auto 0;
    font-size: 12px;
    display: none;
}
