
.indices-search {
    font-size: 14px;
    width: 400px;
    font-weight: 400;
    background-color: var(--color-foreground);
    color: var(--color-text);
    border: none;
    outline: none;
    padding: 10px;
    margin: 10px 0;
}

.indices-search:focus {
    background-color: var(--color-foreground);
    color: var(--color-text);
    box-shadow: none;
}

@media screen and (max-width: 567px) {
    .indices-search {
        width: 100%;
    }
}

.blur-background {
    filter: blur(8px);
    -webkit-filter: blur(8px);
}

.indices-lg-table {
    table-layout: fixed;
    width: 100%;
}

.indices-lg-table > thead > tr > th {
    width: 120px !important;
    /*word-wrap: break-word;*/
}

.ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    max-width: 100%; /* Adjust this as needed */
}


.indices-lg-table > thead > tr > .watchlist-table-index {
    width: 30px !important
}

.indices-lg-table > thead > tr > .watchlist-table-ticker-name {
    width: 1000px !important
}

.indices-lg-table > thead > tr > .watchlist-table-delete {
    width: 50px !important
}

.indices-lg-table > thead > tr > .indices-table-index-name {
    width: 200px !important
}

.price-alerts-table > thead > tr > .indices-table-index-name {
    width: 130px !important
}

.price-alerts-table > thead > tr > .price-alerts-ISIN {
    width: 80px !important
}

.price-alerts-table > thead > tr > .price-alert-active-th {
    width: 50px !important
}

.indices-lg-table > thead > tr > .tsi-table-index-name {
    width: 150px !important
}

.indices-lg-table > thead > tr > .tsi-table-index-code {
    width: 50px !important
}

.indices-lg-table > thead > tr > .tsi-table-index-generic-col {
    width: 80px !important
}

.indices-lg-table > thead > tr > .indices-table-index-flag {
    width: 40px !important
}

.index-row {
    cursor: pointer;
}

.index-row-active {
    background-color: var(--color-headers) !important;
}

.index-top-bar {
    padding: 0 5px;
    width: 100%;
}

.top-bar-container {
    border-radius: 5px;
    overflow-x: auto;
    padding: 15px;
    color: var(--color-text);
    background-color: var(--color-foreground);
    width: 100%;
}

.top-bar-container::-webkit-scrollbar {
    height: 10px
}

.top-bar-container::-webkit-scrollbar-thumb {
    background-color: var(--color-scroll);
}

.top-bar-container::-webkit-scrollbar-track {
    background-color: var(--color-foreground);
}

.top-bar-inner-container {
    display: flex;
    width: 100%;
}

@media screen and (max-width: 1120px) {
    .top-bar-inner-container {
        width: 1000px !important;
    }
}

.bar-component {
    margin-right: auto;
    color: var(--color-text);
}

.indices-lg-table {
    table-layout: fixed;
    width: 100%;
}

.indices-lg-table > thead > tr > th {
    width: 120px !important;
    /*word-wrap: break-word;*/
}

.indices-lg-table > thead > tr > .indices-table-index {
    width: 30px !important
}

.indices-lg-table > thead > tr > .indices-table-code {
    width: 90px !important;
}

.indices-lg-table > thead > tr > .tsi-company-name {
    width: 155px !important
}

.indices-lg-table > thead > tr > .tsi-company-generic-col {
    width: 80px !important
}

.indices-lg-table > thead > tr > .tsi-company-score {
    width: 60px !important
}
.indices-lg-table > thead > tr >.tsi-company-rank {
    width: 60px !important
}

.indices-lg-table > thead > tr > .indices-table-name {
    width: 150px !important
}

.indices-lg-table > thead > tr > .indices-table-star {
    width: 22px !important
}

.indices-lg-table > thead > tr > .indices-table-m-cap {
    width: 100px !important
}

.MuiSvgIcon-root {
    fill: var(--color-text) !important;
}

.index-ticker-modal-body {
    width: 450px;
    background-color: var(--color-foreground);
    padding: 10px;
    border-radius: 5px;
    position: absolute;
}

.add-component-to-watchlist-modal {
    width: 500px;
    min-height: 150px;
    background-color: var(--color-foreground);
    padding: 10px;
    border-radius: 5px;
    position: absolute;
}

.notes-modal-input {
    color: var(--color-text);
    background-color: var(--color-background);
}

.notes-modal-input:focus {
    background-color: var(--color-background);
    font-weight: 600;
    color: var(--color-text);
}

@media screen and (max-width: 567px) {
    .add-component-to-watchlist-modal {
        width: 370px;
    }
}

.add-component-to-watchlist-heading {
    font-size: 17px;
    font-weight: 700;
    color: var(--color-text);
    margin-bottom: 10px;
}

.add-component-to-watchlist-name {
    font-size: 14px;
    font-weight: 600;
    color: var(--color-text);
}

.add-component-to-watchlist-alert {
    font-size: 12px;
    font-weight: 500;
    color: var(--color-text);
}

.add-component-to-watchlist-watchlist {
    height: 200px;
    overflow-y: auto;
    margin: 10px 0;
}

.add-component-to-watchlist-watchlist::-webkit-scrollbar {
    width: 5px
}

.add-component-to-watchlist-watchlist::-webkit-scrollbar-thumb {
    background-color: var(--color-scroll);
}

.add-component-to-watchlist-watchlist::-webkit-scrollbar-track {
    background-color: var(--color-background);
}

.add-component-to-watchlist-watchlist-container {
    display: flex;
    margin: 0 0 10px 0;
}

.add-component-to-watchlist-watchlist-name {
    font-size: 14px;
    font-weight: 600;
    color: var(--color-text);
}

.add-component-to-watchlist-watchlist-check {
    margin-left: auto;
}

.add-component-to-watchlist-new-watchlist-name {
    font-size: 14px;
    font-weight: 600;
    color: var(--color-text);
}

.add-component-to-watchlist-input {
    font-size: 14px;
    font-weight: 400;
    background-color: var(--color-background);
    color: var(--color-text);
    border: none;
    outline: none;
}

.add-component-to-watchlist-input:focus {
    background-color: var(--color-background);
    color: var(--color-text);
    box-shadow: none;
}

.index-stat-table-container {
    padding: 0 5px;
    height: 100%;
}

.index-stat-table-title {
    background-color: var(--color-headers);
    padding: 15px;
    border-radius: 10px 10px 0 0;
    width: 100%;
    display: flex;
}

.index-stat-table-title > p {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;
    color: var(--color-text);
}

.index-stat-table-title > .selected {
    color: var(--color-selected-text);
    cursor: pointer;
}

.index-stat-table-title > .unselected {
    color: var(--color-unselected-text);
    cursor: pointer;
}

.index-stat-table-body-container {
    background-color: var(--color-foreground);
    padding: 10px;
    border-radius: 0 0 10px 10px;
}

.indices-sm-table-body {
    border: none;
}

.indices-sm-table-body > tbody > tr {
    color: var(--color-text);
    border-bottom: none;
    font-size: 12px;
    border-bottom: 0.5px solid #213E44;
}

.indices-sm-table-body > tbody > tr > td {
    border-bottom: none;
    font-size: 11px;
    padding: 5px !important;
}


.index-chart-container {
    margin-bottom: 10px;
    padding: 0 5px;
    height: 100%;
}

.company-overview-chart-container {
    padding: 0 5px;
    height: 100%;
}

.roic-grossmargin-fcfmargin-chart {
    height: auto;
    margin-bottom: 5px;
}

.dividend-chart-container {
    padding: 0 0 0 5px;
    margin-bottom: 5px;
}

@media screen and (max-width: 1198px) {
    .company-overview-chart-container {
        margin-bottom: 10px;
    }
}

@media screen and (max-width: 992px) {
    .dividend-chart-container {
        padding: 0;
    }
}

.dividend-chart-holding-container {
    height: 100%;
    margin: 0;
}

@media screen and (max-width: 992px) {
    .dividend-chart-holding-container {
        margin-top: 10px !important;
    }
}

.index-chart-inner-container {
    width: 100%;
    background-color: var(--color-foreground);
    border-radius: 10px;
    padding: 10px 10px 0 0;
    height: 345px;
}

.company-overview-chart-inner-container {
    width: 100%;
    background-color: var(--color-foreground);
    border-radius: 10px;
    padding: 10px 10px 0 0;
    height: 346px;
}

.isqore-matrix-chart-inner-container {
    width: 100%;
    background-color: var(--color-foreground);
    border-radius: 10px;
    padding: 10px 10px 0 0;
    height: 332.5px;
}

.index-iSqore-value {
    color: var(--color-text);
    font-size: 15px;
    font-weight: 600;
}

.company-overview-bottom-table {
    margin-top: 10px
}

@media screen and (max-width: 992px) {
    /*.company-overview-bottom-table {*/
    /*    margin-top: 10px*/
    /*}*/
}
