
.rule-of-forty-description {
    padding: 15px 0;
    border-radius: 10px;
    margin: 0;
}

.rule-of-forty-description > p {
    color: var(--color-text);
    font-size: 14px;
}

.rule-of-forty-description > p > a {
    color: var(--color-text);
    font-weight: 600;
}

.table-head {
    border: none;
}

.table-head > tr > th {
    font-size: 10px;
    border: none;
    padding: 12px;
}

.table-head > tr {
    color: white;
}

.table-body > tr > td {
    font-size: 10px;
    font-weight: 400;
    border: none;
    padding: 10px;
    color: white;
}

.rule-of-forty-sm-table-body > tr {
    border-bottom: 0.5px solid rgb(33, 62, 68)
}

.table-body > tr {
    background-color: #0C1B29;
    color: white;
}

.entire-tabs-container > .MuiBox-root > .MuiTabs-root > .MuiTabs-scrollable > .MuiTabs-flexContainer > .MuiButtonBase-root {
    text-transform: none;
    font-size: 14px;
}

.entire-tabs-container.dark > .MuiBox-root > .MuiTabs-root > .MuiTabs-scrollable > .MuiTabs-flexContainer > .MuiButtonBase-root > .MuiTab-wrapper {
    color: #ffffff !important;
}

.entire-tabs-container.light > .MuiBox-root > .MuiTabs-root > .MuiTabs-scrollable > .MuiTabs-flexContainer > .MuiButtonBase-root > .MuiTab-wrapper {
    color: black !important;
}

.entire-tabs-container.dark > .MuiBox-root > .MuiTabs-root > .MuiTabs-scrollable > .MuiTabs-flexContainer > .MuiButtonBase-root > .MuiTab-wrapper {
    color: #ffffff !important;
}

.entire-tabs-container.dark > .MuiBox-root > .MuiTabs-root > .MuiButtonBase-root > .MuiSvgIcon-fontSizeSmall {
    color: white;
}

.entire-tabs-container.light > .MuiBox-root > .MuiTabs-root > .MuiButtonBase-root > .MuiSvgIcon-fontSizeSmall {
    color: black;
}

.entire-tabs-container.light > .MuiBox-root > .MuiTabs-root > .MuiTabs-scrollable > .MuiTabs-flexContainer > .MuiButtonBase-root > .MuiTab-wrapper {
    color: black !important;
}


.rule-of-forty-filter-modal {
    width: 370px;
}

.rule-of-forty-search-by-filter-btn {
    padding: 10px;
    font-size: 12px;
    font-weight: 600;
    width: 100%;
    text-align: left;
    display: flex;
    background-color: #0C1B29;
    border: 1px solid #ada5a5;
}

.rule-of-forty-close-modal-button {
    margin: auto 0 auto auto;
    font-size: 10px;
    color: white;
    cursor: pointer;
}

.rule-of-forty-exchange-list-container {
    padding: 10px;
    cursor: pointer;
    display: flex;
}

.rule-of-forty-exchange-list-container > p {
    font-size: 12px;
    margin: auto 0 auto 10px;
}

.rule-of-forty-exchange-list-container:hover {
    background-color: #132A3F;
}

.rule-of-forty-filter-search-field {
    font-size: 10px;
}

.rule-of-forty-filter-list {
    width: 100%;
    padding: 10px;
    top: 45px;
    left: 0;
    position: absolute;
    z-index: 1000;
    background-color: #132A3F;
}

.rule-of-forty-filter-list-exchange-container {
    display: flex;
    font-size: 12px;
    font-weight: 700;
    padding: 10px;
    cursor: pointer;
}

.rule-of-forty-filter-list-exchange-container:hover {
    background-color: #03111E;
}

.rule-of-40-table-container {
    margin-bottom: 10px;
    padding: 0 5px;
}

.rule-of-40-table-body-container {
    background-color: var(--color-foreground);
    padding: 10px;
}

.rule-of-40-table-title {
    background-color: var(--color-headers);
    padding: 15px;
    border-radius: 10px 10px 0 0;
    width: 100%;
}

.rule-of-40-table-title > p {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;
    color: var(--color-text);
}

.rule-of-40-table > tbody > tr > td {
    padding: 7px !important;
}

.rule-of-forty-chart-container {
    margin-bottom: 10px;
    padding: 0 5px;
}

.rule-of-forty-inner-container {
    width: 100%;
    background-color: var(--color-foreground);
    border-radius: 10px;
    padding: 20px 10px;
    height: 400px;
}

.rule-of-forty-chart-name {
    font-size: 15px;
    font-weight: 700;
    color: var(--color-text);
    margin-left: 17px;
}


.rule-of-40-lg-table {
    table-layout: fixed;
    width: 100%;
}

.rule-of-40-lg-table > thead > tr > th {
    width: 120px !important;
    /*word-wrap: break-word;*/
}

.rule-of-40-lg-table > thead > tr > .rule-of-forty-name {
    width: 150px !important;
}

.rule-of-40-lg-table > thead > tr > .rule-of-forty-industry {
    width: 150px !important;
}

.rule-of-40-lg-table > thead > tr > .rule-of-forty-watchlist {
    width: 80px !important;
}