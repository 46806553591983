/* Index Section */
.indices-container {
    padding-bottom: 5px;
}

.indices-container::-webkit-scrollbar {
    height: 5px;
}

.indices-container::-webkit-scrollbar-thumb {
    background-color: var(--color-scroll);
}

.indices-container::-webkit-scrollbar-track {
    background-color: var(--color-foreground)
}

.indices-container {
    display: flex;
    min-width: calc(100vw - 140px);
    overflow-x: auto;
}

@media screen and (max-width: 768px) {
    .indices-container {
        width: calc(100vw - 50px);
    }
}

.index {
    padding: 12px;
    margin: 0 10px 0 0;
    border-radius: 10px;
    background-color: var(--color-foreground);
}

.index-name {
    font-size: 10px;
    font-weight: 600;
    color: var(--color-text);
}

.index-text-container {
    display: flex;
    margin-top: 10px;
}

.index-cash {
    font-size: 13px;
    font-weight: 700;
    margin: auto;
    color: var(--color-text);
}

.index-first-num {
    font-size: 10px;
    font-weight: 600;
    margin: auto 10px;
}

.index-second-num {
    font-size: 10px;
    font-weight: 600;
    margin: auto 0;
}
